import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';


const HelmetSeo = ({
    title,
    description,
    type = 'article',
    imageUrl = 'https://myopinion.ci/favicon.png'
}) => {

    const [titleMeta, setTitleMeta] = useState('MyOpinion')
    const [descMeta, setDescMeta] = useState('MyOpinion. The world is listening.')
    const [typeMeta, setTypeMeta] = useState('article')
    const [ImageMeta, setImageMeta] = useState('https://myopinion.ci/favicon.png')

    useEffect(() => {
        setTitleMeta(title)
        setDescMeta(description)
        setImageMeta(imageUrl)
    }, [title, description, type, imageUrl])


    return (
        <Helmet>
            <title>{titleMeta}</title>
            <meta property='og:type' content={typeMeta} />
            <meta property='og:title' content={titleMeta} />
            <meta property='og:description' content={descMeta} />
            <meta property='og:image' content={ImageMeta} />

            <meta name="twitter:card" content={typeMeta} />
            <meta name="twitter:title" content={titleMeta} />
            <meta name="twitter:description" content={descMeta} />
        </Helmet>
    )
}

export default HelmetSeo