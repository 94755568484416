import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineDisconnect } from 'react-icons/ai'
import { FaUserTimes } from 'react-icons/fa'
import { MdPersonAddAlt1 } from 'react-icons/md'
import { TbPlugConnected } from 'react-icons/tb'
import { api } from '../../../api/api'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import ProfileCard from '../../ProfileCard/ProfileCard'

const Sent = ({
    item,
    user,
    refresh
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const [isLoading, setIsLoading] = useState(false)


    const cancelBuddy = async () => {
        try {
            setIsLoading(true)
            const body = { user_id: item?.user_id }
            const request = await api(
                'api/profile/request-cancel-buddy',
                'DELETE',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {
                    refresh()
                }
            }
            setIsLoading(false)
        } catch ({ message }) {
            setIsLoading(false)
            throw new Error(message)
        }
    }




    const renderButton = () => {
        return (
            <button
                onClick={cancelBuddy}
                className='button'
                style={{
                    width: '135px',
                    backgroundColor: 'white',
                    fontSize: "13px",
                    color: "red",
                    fontWeight: '600',
                    border: '1.8px red solid'
                }}>

                {!isLoading ?
                    <>
                        <FaUserTimes size={18} style={{ marginRight: '5px' }} />
                        {t('cancel')}
                    </>
                    :
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant="danger"
                    />
                }

            </button>
        )

    }


    return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{ width: '70%' }}>
                <ProfileCard
                    user={item}
                    pictureSize={55}
                />
            </div>
            {authData?.user?.user_id == user?.user_id &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '30%', paddingRight: '15px' }}>
                    {renderButton()}
                </div>
            }
        </div>
    )
}

export default Sent