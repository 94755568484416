import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { api } from '../../api/api'
import { useAccessToken } from '../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../hooks/Utils/color'
import ButtonSubmit from '../ButtonSubmit/ButtonSubmit'
import Header from '../Header/Header'

const ModalReport = ({
    user_id = 0,
    sond_id = 0,
    com_id = 0,
    show,
    setShow
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const [reasons, setReasons] = useState([])
    const [otherReason, setOtherReason] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const goBack = () => {
        setShow(false)
        setOtherReason([])
        setOtherReason(null)
    }


    const REPORT_SECTION = {
        OPINION: 'opinion',
        COMMENT: 'comment',
        CONVERSATION: 'conversation',
    }


    const reportOpinion = [
        t('reportOpinion.sexualContent'),
        t('reportOpinion.violent'),
        t('reportOpinion.hateful'),
        t('reportOpinion.harassment'),
        t('reportOpinion.harmful'),
        t('reportOpinion.childAbuse'),
        t('reportOpinion.infrigeRight'),
        t('reportOpinion.terrorism'),
        t('reportOpinion.spam'),
    ]

    const reportComment = [
        t('reportComment.commercial'),
        t('reportComment.sexual'),
        t('reportComment.childAbuse'),
        t('reportComment.hateSpeech'),
        t('reportComment.harassment'),
    ]

    const handleReport = async () => {
        try {

            // REPORT_SECTION.CONVERSATION ne fait référence qu'au report lié aux oneToOne
            // alors dans les autres cas il aura la valeur null par défaut
            setIsLoading(true)
            const body = {
                type: REPORT_SECTION.OPINION,
                user_id,
                post_id: sond_id,
                com_id,
                reasons: reasons,
                info: otherReason,
            }
            const request = await api('api/report', 'POST', body, accessToken)
            const response = await request.json()
            console.log('Response report:', response.message)
            if (request.status === 200) {
                if (response.success) {
                    setReasons([])
                    setOtherReason('')
                    setShow(false)
                }
            }
            setShow(false)
            setIsLoading(false)
        } catch ({ message }) {
            setShow(false)
            setIsLoading(false)
            throw new Error(message)
        }
    }



    const onChange = (value, report) => {
        if (value) {
            setReasons(reasons => [...reasons, report])
        } else {
            setReasons(reasons => reasons.filter(reason => reason !== report))
        }
        console.log(reasons)
    }

    return (
        <Modal show={show}>

            <Header title={`${t('report')} opinion`} type='modal' goBack={goBack} />

            <div style={{ padding: '10px' }}>

                {reportOpinion.map(report => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type={'checkbox'}
                            value={report}
                            onChange={(e) => onChange(e.target.checked, report)}
                            color={color.secondary}
                        />
                        <span style={{ marginLeft: '10px' }}>
                            {report}
                        </span>
                    </div>
                ))}

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type={'checkbox'}
                        value={t('reportOpinion.other')}
                        onChange={(e) => onChange(e.target.checked, t('reportOpinion.other'))}
                    />
                    <span style={{ marginLeft: '10px' }}>
                        {t('reportOpinion.other')}
                    </span>
                </div>

                <textarea
                    value={otherReason} onChange={(e) => setOtherReason(e.target.value)}
                    placeholder='Entrez la raison...'
                    style={{
                        width: '100%',
                        marginTop: '10px',
                        marginBottom: '20px'
                    }}>

                </textarea>

                <ButtonSubmit
                    value={t('report')}
                    onClick={handleReport}
                    isLoading={false}
                    style={{
                        width: '100%',
                        color: 'white'
                    }}
                />

            </div>

        </Modal>
    )
}

export default ModalReport