import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineDisconnect } from 'react-icons/ai'
import { FaUsers, FaUserTimes } from 'react-icons/fa'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { TbPlugConnected } from 'react-icons/tb'
import { TabPanel, Tab, Tabs, TabList } from 'react-tabs'
import { api } from '../../api/api'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import Endbar from '../Endbar/Endbar'
import Sidebar from '../Sidebar/Sidebar'
import './Audience.style.css'
import Blocked from './Blocked/Blocked'
import Buddy from './Buddy/Buddy'
import Fan from './Fan/Fan'
import FanOf from './FanOf/FanOf'
import Received from './Received/Received'
import Sent from './Sent/Sent'
import { isMobile } from 'react-device-detect';


const Audience = ({
    user,
    show = false,
    setShow
}) => {

    const { t } = useTranslation()
    const { data: authData } = useAuthReducer()
    const accessToken = useAccessToken()
    const controller = new AbortController();
    const signal = controller.signal;
    const [buddies, setBuddies] = useState([])
    const [fans, setFans] = useState([])
    const [fansOf, setFansOf] = useState([])
    const [requestSent, setRequestSend] = useState([])
    const [requestReceived, setRequestReceived] = useState([])
    const [blocked, setBlocked] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        if (!show) {
            return
        }
        console.log('Audience...')
        fetchAudience()
        return () => controller.abort();
    }, [show])

    const fetchAudience = async () => {
        console.log('Audience updating...')
        try {
            setIsLoading(true)
            const request = await api(`api/user/audience/${user?.user_id}`, 'GET', null, { ...signal, ...accessToken })
            const response = await request.json()
            console.log('Response fetch audience:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    if (response?.audience[0]) {
                        setBuddies([
                            ...response?.audience[0]?.friends_invited,
                            ...response?.audience[0]?.friends_accepted
                        ])
                        setFans(response?.audience[0]?.followers)
                        setFansOf(response?.audience[0]?.followings)
                        setRequestSend(response?.audience[0]?.invite_sent)
                        setRequestReceived(response?.audience[0]?.invite_received)
                        setBlocked(response?.audience[0]?.blocked)
                    }
                }
            }
            setIsLoading(false)
        } catch ({ message }) {
            setIsLoading(false)
            throw new Error(message)
        }
    }


    const renderEmpty = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <FaUsers size={58} color='#c0c0c0' />
                    <h6 style={{ color: '#c0c0c0', marginTop: '10px' }}>{t('no_buddy')}</h6>
                </div>
            </div>
        )
    }

    const renderLoading = () => {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant="secondary"
                    />
                    <h6 style={{ color: '#808080', marginTop: '10px' }}>
                        {t('loading_audience')}
                    </h6>
                </div>
            </div>
        )
    }

    return (
        <Modal show={show} fullscreen={true} animation={false}>
            <div className='user-audience'>

                {!isMobile &&
                    <div style={{ width: '40%' }}>
                        <Sidebar />
                    </div>
                }
 
                <div className='content' style={{ width: isMobile ? '100%' : '60%' }}>

                    <div style={{ width: isMobile ? '100%' : '44.5%' }} className='topbar'>
                        <div onClick={() => setShow(false)} className='button-back' >
                            <MdOutlineKeyboardBackspace size={32} />
                        </div>
                        <h5 className='title'>
                            {user?.user_surname} {user?.user_name} - Audience
                        </h5>
                    </div>

                    <div className='content-body'>
                        {isLoading ? renderLoading() :
                            <Tabs defaultIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

                                <TabList className='tablist'>
                                    <Tab className='tab' selectedClassName="tab-selected">{t('buddies')}</Tab>
                                    <Tab className='tab' selectedClassName="tab-selected">{t('fans')}</Tab>
                                    <Tab className='tab' selectedClassName="tab-selected">{t('fan_of')}</Tab>
                                    {authData?.user?.user_id == user?.user_id &&
                                        <>
                                            <Tab className='tab' selectedClassName="tab-selected">{t('request')}</Tab>
                                            <Tab className='tab' selectedClassName="tab-selected">{t('sent')}</Tab>
                                            <Tab className='tab' selectedClassName="tab-selected">{t('blocked')}</Tab>
                                        </>
                                    }
                                </TabList>

                                <TabPanel className='tabpanel'>
                                    {buddies.length == 0 ? renderEmpty() :
                                        buddies.sort((a, b) => a.user_surname.localeCompare(b.user_surname)).map(item => {
                                            return (
                                                <Buddy
                                                    user={user}
                                                    item={item}
                                                    refresh={fetchAudience}
                                                />
                                            )
                                        })
                                    }

                                </TabPanel>

                                <TabPanel>
                                    {fansOf.length == 0 ? renderEmpty() :
                                        fans.sort((a, b) => a.user_surname.localeCompare(b.user_surname)).map(item => {
                                            return (
                                                <Fan
                                                    user={user}
                                                    item={item}
                                                    refresh={fetchAudience}
                                                />
                                            )
                                        })
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {fansOf.length == 0 ? renderEmpty() :
                                        fansOf.sort((a, b) => a.user_surname.localeCompare(b.user_surname)).map(item => {
                                            return (
                                                <FanOf
                                                    user={user}
                                                    item={item}
                                                    refresh={fetchAudience}
                                                />
                                            )
                                        })
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {requestReceived.length == 0 ? renderEmpty() :
                                        requestReceived.sort((a, b) => a.user_surname.localeCompare(b.user_surname)).map(item => {
                                            return (
                                                <Received
                                                    user={user}
                                                    item={item}
                                                    refresh={fetchAudience}
                                                />
                                            )
                                        })
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {requestSent.length == 0 ? renderEmpty() :
                                        requestSent.sort((a, b) => a.user_surname.localeCompare(b.user_surname)).map(item => {
                                            return (
                                                <Sent
                                                    user={user}
                                                    item={item}
                                                    refresh={fetchAudience}
                                                />
                                            )
                                        })
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {blocked.length == 0 ? renderEmpty() :
                                        blocked.sort((a, b) => a.user_surname.localeCompare(b.user_surname)).map(item => {
                                            return (
                                                <Blocked
                                                    user={user}
                                                    item={item}
                                                    refresh={fetchAudience}
                                                />
                                            )
                                        })
                                    }
                                </TabPanel>
                            </Tabs>
                        }
                    </div>

                </div>

                {!isMobile &&
                    <div style={{ width: '35%' }}>
                        <Endbar />
                    </div>
                }
            </div>
        </Modal>
    )
}

export default Audience