import React from 'react'
import './PeopleYouMatch.style.css'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header/Header'
import { usePeopleMatchReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import RenderPeople from '../../components/PeopleMatchCarousel/RenderPeople/RenderPeople'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

const PeopleYouMatch = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: peopleData } = usePeopleMatchReducer()

  return (
    <div>
      <Header title={t('people_you_match')} goBack={() => navigate(-1)} />

      <div style={{marginLeft: '15px', gridTemplateColumns: isMobile ? 'repeat(1, 2fr)' : 'repeat(2, 1fr)'}} className="grid-container">
        {peopleData.people.map((people, index) => {
          return (
            <div style={{ width: '100%' }}>
              <RenderPeople
                key={index.toString()}
                people={people}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PeopleYouMatch