import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiSendPlaneFill } from 'react-icons/ri'
import { api } from '../../api/api'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import './CommentInput.style.css'
import InputEmoji from "react-input-emoji";
import useNotificationPush from '../../hooks/useNotificationPush/useNotificationPush'

const CommentInput = ({
    sond_id,
    com_user,
    com_type,
    com_text = '',
    com_parent = 0,
    sub_com_id = 0,
    sond_user,
    sond_caption,
    updateOpinion,
    setShowUnauthModal,
    sourceFrom
}) => {

    const { data: AuthData } = useAuthReducer()
    const accessToken = useAccessToken()
    const sendNotification = useNotificationPush()
    const { t } = useTranslation()
    const [textComment, setTextComment] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [showEmoji, setShowEmoji] = useState(false)

    const handleComment = async (e) => {
        try {
            if (e) {
                e.preventDefault();
            }
            if (sourceFrom == 'Feed') {
                setShowUnauthModal(true)
                return
            }
            setIsLoading(true)

            const body = {
                com_user: com_user,
                com_sond: sond_id,
                com_type: com_type,
                com_text: textComment,
                com_parent: com_parent,
                sub_com_id: sub_com_id
            }
            const request = await api(`api/comments`, 'POST', body, accessToken)
            const response = await request.json()
            console.log(response)
            if (request.ok && request.status === 200) {
                setTextComment('')
                setError('')
                updateOpinion(response.opinion)

                // SEND NOTIFICATION
                sendNotification(com_user, {
                    title: `${AuthData?.user?.user_surname} ${AuthData?.user?.user_name
                        } ${com_parent
                            ? 'a répondu'
                            : com_type === 'c'
                                ? 'a commenté'
                                : 'a suggéré'
                        }:`,
                    body: textComment.replace(/[0-9]/g, ''),
                    url: `https://myopinion.ci/opinion/select/0/${sond_id}`,
                })
            } else {
                setError(t('incorrect_email_address_or_password'))
            }
            setIsLoading(false)
        } catch (error) {
            setError(t('an_error_occurred_please_try_again_later'));
            setIsLoading(false)
            console.warn(error);
        }
    }

    return (
        <div>
            <form onSubmit={handleComment} className='comment-input-component'>

                <ProfilePicture
                    user={AuthData?.user}
                    uri={AuthData?.user?.profile?.prof_picture}
                    size={33}
                />

                {/* <input
                    value={textComment}
                    onChange={event => setTextComment(event.target.value)}
                    // type='text'
                    // onFocus={()}
                    onBlur={() => setShowEmoji(false)}
                    placeholder='Écrivez un commentaire'
                /> */}
                
                <InputEmoji
                    value={textComment}
                    onChange={setTextComment}
                    cleanOnEnter
                    keepOpened
                    onEnter={text => handleComment()}
                    theme={'light'}
                    placeholder="Écrivez votre commentaire"
                />
                <button type='submit' onClick={handleComment} >
                    <RiSendPlaneFill size={26} color='#1880a1' style={{ backgroudColor: '#1880a1', border: '1px #1880a1 solid', borderRadius: '30px', padding: '3px' }} />
                </button>
            </form>
        </div>
    )
}

export default CommentInput