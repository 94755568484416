import React from 'react'
import './Public.style.css'
import PublicOpinions from '../../components/Opinions/PublicOpinions/PublicOpinions';
import { useTranslation } from 'react-i18next';
import HelmetSeo from 'components/HelmetSeo/HelmetSeo';

const Public = () => {

  // STATE
  const { t, i18n } = useTranslation()

  return (
    <div className='public'>
     <HelmetSeo
        title={`${
          i18n.language === 'en'
          ? 'Public - MyOpinion'
          :  'Publique - MyOpinion'
        }`}
        description={t('login_now_to_interact_with_thousands_of_people')}
      />
      <PublicOpinions />
    </div>
  )
}

export default Public