import {useOneToOneReducer } from "../ReducerHooks/ReducerHooks"


const useReducerOneToOneAction = () => {

    const { store: oneToOneStore } = useOneToOneReducer()

    const REFRESH_MESSAGE_NUMBER = (data) => {
        oneToOneStore({
            type: 'REFRESH_MESSAGE_NUMBER',
            payload: data
        })
    }

    const REFRESH_CONVERSATION = (data) => {
        oneToOneStore({
            type: 'REFRESH_CONVERSATION',
            payload: data
        })
    }

    const CLEAR_CONVERSATION = (data) => {
        oneToOneStore({
            type: 'CLEAR_CONVERSATION'
        })
    }

    const REFRESH_BUDDIES = (data) => {
        oneToOneStore({
            type: 'REFRESH_BUDDIES',
            payload: data
        })
    }

    const CLEAR_BUDDIES = (data) => {
        oneToOneStore({
            type: 'CLEAR_BUDDIES'
        })
    }

    return {REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, CLEAR_CONVERSATION, REFRESH_BUDDIES, CLEAR_BUDDIES}
}

export default useReducerOneToOneAction