import { useRandomReducer } from "../ReducerHooks/ReducerHooks"


export default function useReducerRandomAction() {

    const { store: RandomStore } = useRandomReducer()

    const REFRESH_RANDOM_OPINIONS = (data) => {
        if(data.length == 0){
            return
        }
        RandomStore({
            type: 'REFRESH_RANDOM_OPINIONS',
            payload: data
        })
    }


    const ADD_RANDOM_OPINIONS = (data) => {
        if(data.length == 0){
            return
        }
        RandomStore({
            type: 'ADD_RANDOM_OPINIONS',
            payload: data
        })
    }

    const UPDATE_RANDOM_OPINION = (data) => {
        if(!data){
            return
        }
        RandomStore({
            type: 'UPDATE_RANDOM_OPINION',
            payload: data
        })
    }

    const UPDATE_RANDOM_VIEW_OPINION = (data) => {
        if(!data){
            return
        }
        RandomStore({
            type: 'UPDATE_RANDOM_VIEW_OPINION',
            payload: data
        })
    }

    const UPDATE_RANDOM_VOTE = (data) => {
        if(!data){
            return
        }
        RandomStore({
            type: 'UPDATE_RANDOM_VOTE',
            payload: data
        })
    }

    const DELETE_RANDOM_OPINION = (data) => {
        if(!data){
            return
        }
        RandomStore({
            type: 'DELETE_RANDOM_OPINION',
            payload: data
        })
    }

    const MUTED_USER_RANDOM_OPINION = (data) => {
        if (!data) {
            return
        }
        RandomStore({
            type: 'MUTED_USER_RANDOM_OPINION',
            payload: data
        })
    }

    const CLEAR_RANDOM_OPINIONS = () => {
        RandomStore({
            type: 'CLEAR_RANDOM_OPINIONS'
        })
    }

    return { REFRESH_RANDOM_OPINIONS, ADD_RANDOM_OPINIONS, UPDATE_RANDOM_OPINION, UPDATE_RANDOM_VIEW_OPINION, UPDATE_RANDOM_VOTE, DELETE_RANDOM_OPINION, MUTED_USER_RANDOM_OPINION, CLEAR_RANDOM_OPINIONS }

}
