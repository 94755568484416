import React from 'react'
import { memo } from 'react'

const Image = ({ attachment }) => {


    return (
        <div style={{ marginBottom: '10px' }}>
            <img src={attachment.attach_url} width={'100%'} alt='' style={{borderRadius: '5px'}} />
        </div>
    )
}

export default memo(Image)