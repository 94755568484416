const initialState = {
    opinions: [],
};

const RandomReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'REFRESH_RANDOM_OPINIONS':
            return {
                ...state,
                opinions: action?.payload,
            }

        case 'ADD_RANDOM_OPINIONS':
            return {
                ...state,
                opinions: [...state.opinions, ...action.payload],
            }

        case 'UPDATE_RANDOM_OPINION':
            return {
                ...state,
                opinions: state.opinions.map(opinion =>
                    opinion.sond_id !== action.payload.sond_id ? opinion : action.payload,
                ),
            }

        case 'UPDATE_RANDOM_VIEW_OPINION':
            return {
                ...state,
                opinions: state.opinions.map(opinion =>
                    opinion.sond_id !== action?.payload?.sond_id
                        ? opinion
                        : { ...opinion, ...action.payload },
                ),
            }

        case 'UPDATE_RANDOM_VOTE':
            return {
                ...state,
                opinions: state.opinions.map(opinion =>
                    opinion.sond_id === action.payload
                        ? {
                            ...opinion,
                            votes_count: ++opinion.votes_count,
                            get_user_vote: [{ vote_opinion: action.payload.toString() }],
                        }
                        : opinion,
                ),
            }

        case 'DELETE_RANDOM_OPINION':
            return {
                ...state,
                opinions: state.opinions.filter(
                    opinion => opinion.sond_id !== action.payload.sond_id,
                ),
            }

        case 'MUTED_USER_RANDOM_OPINION':
            return {
                ...state,
                opinions: state.opinions.filter(
                    opinion => opinion.sond_user !== action.payload,
                ),
            }

        case 'CLEAR_RANDOM_OPINIONS':
            return {
                ...state,
                opinions: [],
            }

        default:
            return state;
    }
}

export default RandomReducer;