import React from 'react'
import './PublicOpinions.style.css'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import RandomOpinions from './RandomOpinions/RandomOpinions'
import RecentOpinions from './RecentOpinions/RecentOpinions'
import ViralOpinions from './ViralOpinions/ViralOpinions'
import TopBar from '../../Topbar/Topbar'
import { isMobile } from 'react-device-detect'

const PublicOpinions = () => {

  // STATE
  const { t } = useTranslation()
  const [tabView, setTabView] = useState(1)

  return (
    <div className='public-opinions'>
      {isMobile && <TopBar />}
      <div className='header'>

        <button onClick={() => setTabView(1)} className='tab-head' style={{ borderBottom: tabView == 1 ? '3px #1880a1 solid' : 'none' }}>
          <h6 style={{ color: tabView == 1 ? '#1880a1' : '#474747' }}>
            {t('forYou')}
          </h6>
        </button>

        <button onClick={() => setTabView(2)} className='tab-head' style={{ borderBottom: tabView == 2 ? '3px #1880a1 solid' : 'none' }}>
          <h6 style={{ color: tabView == 2 ? '#1880a1' : '#474747' }}>
            {t('recent')}
          </h6>
        </button>

        <button onClick={() => setTabView(3)} className='tab-head' style={{ borderBottom: tabView == 3 ? '3px #1880a1 solid' : 'none' }}>
          <h6 style={{ color: tabView == 3 ? '#1880a1' : '#474747' }}>
            {t('viral')}
          </h6>
        </button>

      </div>

      <div style={{ display: tabView !== 1 ? 'none' : '' }}>
        <RandomOpinions />
      </div>

      <div style={{ display: tabView !== 2 ? 'none' : '' }}>
        <RecentOpinions />
      </div>

      <div style={{ display: tabView !== 3 ? 'none' : '' }}>
        <ViralOpinions />
      </div>

    </div>
  )
}

export default PublicOpinions