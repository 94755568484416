const en = {
    translation: {
        /*=========== LOGIN */
        "download_myopinion": "Téléchargez Myopinion",
        "with_myopinion_share_and_stay": "Le monde t'écoute.",
        "login_now_to_interact_with_thousands_of_people": "Connectez-vous maintenant pour interagir avec des millions personnes.",
        "connect_with": "Connectez vous avec",
        "login_with_gmail": "Connexion avec Gmail",
        "login_with_facebook": "Connexion avec facebook",
        "login_with_apple": "Connexion avec Apple",
        "create_an_account": "Créer un compte",
        "reset": "Réinitialiser",
        "or": "ou",
        "login": "Connexion",
        "email": "Adresse mail",
        "password": "Mot de passe",
        "new_password": "Nouveau mot de passe",
        "confirm_password": "Confirmez le mot de passe",
        "forgot_password": "Mot de passe oublié",
        "forgot_your_password": "Mot de passe oublié ?",
        "please_enter_your_account_email_below_to_reset_it": "Veuillez entrer l'email de votre compte ci-dessous pour le réinitialisé.",
        "terms_and_conditions": "Conditions générales",
        "privacy_policy": "Politique de confidentialité",
        'french_france': "Français (France)",
        'english_us': "Anglais (US)",
        "link_terms_of_use": "https://payesha.com/legal/fr/conditions-generales.html",
        "link_privacy_policy": "https://payesha.com/legal/fr/conditions-generales.html",
        "incorrect_email_address_or_password": "Adresse mail ou mot de passe incorrect.",
        "an_error_occurred_please_try_again_later": "Une erreur s'est produite, veuillez réessayer plus tard.",
        "trend_for_you": "Tendance pour vous",
        "enter_your_new_password": "Entrez votre nouveau mot de passe",

        /*============ REGISTER */
        "sign_up": "Inscrivez-vous",
        "you_do_not_have_an_account": "Vous n'avez pas de compte ? Créez votre compte, cela prend moins d'une minute",
        "name": "Prénom",
        "surname": "Nom",
        "nickname": "Pseudonyme",
        "civility": "Civilité",
        "phone_number": "Numéro de téléphone",
        "city": "Ville",
        "country": "Pays",
        "register": "S'inscrire",
        "confirm": "Confirmer",
        "continue": "Continuer",
        "confirmation_code": "Code de confirmation",
        "account_verification": "Vérification de compte",
        "a_6_digit_code_has_just_been_sent": "Un code de 6 chiffres vient d'être envoyé à votre adresse mail",
        "if_you_dont_see_the_email_look_in_your_spam": "Si vous ne voyez pas le mail regardez dans vos spams.",
        "password_reset": "Réinitialisation de mot de passe.",


        /*========== ERROR */
        "your_surname_must_contain_at_least_4_characters": "Votre nom doit contenir au moins 4 caractères",
        "your_nickname_must_contain_at_least_4_characters_only_underscore": "Votre pseudonyme doit contenir au moins 4 caractères, le tiret du bas ( _ ) est autorisé.",
        'invalid_confirmation_code': "Code de confirmation invalide.",
        'invalid_confirmation_code_at_least_6_characters': "Code de confirmation invalide, le code de confirmation doit contenir au moins 6 caractères.",
        'invalid_email': "Adresse mail invalide.",
        'invalid_password': "Mot de passe invalide, votre mot de passe doit contenir au moins 8 caractères.",
        'passwords_do_not_match': 'Les mots de passe ne correspondent pas.',

        /*============ ERROR REQUEST */
        "the_email_is_already_taken": "L'adresse mail est déjà utilisé.",
        "no_internet_connection": "Aucune connexion internet, vérifiez votre connexion internet et réessayer.",
        "this_email_does_not_have_an_account": "Ce Mail n'a pas de compte.",

        /*========= OPINIONS */
        "audience": "Audience",
        "public": "Publique",
        "recent": 'Récentes',
        "random": 'Aléatoires',
        "viral": 'Virales',
        "for_you": 'Pour toi',
        loading: 'Chargement...',
        "loading_opinion": 'Opinion en chargement...',
        "loading_recent": 'Récentes en chargement...',
        "loading_random": 'Aléatoires en chargement...',
        "loading_for_you": 'Pour toi en chargement...',
        "loading_viral": 'Virales en chargement...',
        "loading_audience": 'Potes en chargement...',
        "loading_profile": 'Profil en chargement...',

        //=========== PROFILE
        profile: 'Profil',
        setting: 'Paramètre',
        language: 'Langue',
        account: 'Compte',
        update: 'Mettre à jour',
        update_your_login_settings_associated_with_your_account: 'Mettez à jour vos paramètres de connexion associés à votre compte.',


        buddy: "Pote",
        buddies: "Potes",
        become_buddy: 'Devenir pote',
        you_are_buddy: 'Tu es pote',
        fan: 'Fan',
        fan_of: 'Fan de',
        fans: 'Fans',
        become_fan: 'Devenir fan',
        you_are_fan: 'Tu es fan',
        approve: 'Accepter',
        approve_request: 'Accepter demande',
        Received: 'Reçues',
        request: 'Demande',
        sent: 'Envoyées',
        cancel: ' Annuler',
        block: 'Bloquer',
        blocked: 'Bloqué(s)',
        unlock: 'Débloquer',
        no_buddy: 'Aucun pote',


        //========== BADGE

        /* Goal */
        goal: 'Objectif de vie',
        love: 'Amour',
        god: 'Dieu',
        money: 'Argent',
        power: 'Pouvoir',
        fame: 'Célébrité',
        family: 'Famille',
        humanitary: 'Humanitaire',
        patriot: 'Patriote',
        peace: 'Paix',
        freedom: 'Freedom',
        saveThePlanet: 'Sauver La Planète',


        /* Business */
        business: 'Travail',
        technology: 'Technologie',
        finance: 'Management & Finance',
        arts: 'Arts',
        health: 'Santé',
        foodIndustry: 'Nourriture',
        education: 'Éducation',
        law: 'Droit',
        restaurant: 'Restauration',
        communication: 'Communication',
        services: 'Services',
        proAthlete: 'Athlète Pro',
        agriculture: 'Agriculture',


        /* Hooby */
        hobby: 'Loisir',
        travel: 'Voyage',
        sport: 'Sport',
        cinema: 'Cinema',
        nightLife: 'Soirée dansante',
        communityLife: 'Vie communautaire',
        art: 'Art',
        gardening: 'Jardinage',
        hike: 'Randonnée',
        hunting: 'Chasse',
        fishing: 'Pêche',
        music: 'Musique',
        videoGame: 'Jeux vidéo',


        /* Food */
        food: 'Nourriture',
        vegetarian: 'Végétarien',
        meat: 'Viande',
        fish: 'Poisson',
        bread: 'Pain',
        cake: 'Gâteau',
        pasta: 'Pâtes',
        soup: 'Soupe',
        seaFood: 'Fruits de mer',
        cereals: 'Céréales',
        dairy: 'Produits laitiers',
        rice: 'Riz',
        wildMeat: 'Viande de brousse',

        /* Color */
        color: 'Couleur',
        red: 'Rouge',
        blue: 'Bleu',
        green: 'Vert',
        yellow: 'Jaune',
        orange: 'Orange',
        black: 'Noir',
        white: 'Blanc',
        purple: 'Violet',
        pink: 'Rose',
        brown: 'Marron',
        grey: 'Gris',
        cyan: 'Cyan',
        beige: 'Beige',

        select_at_least_one_badge_per_category: 'Sélectionnez au moins un badge par catégorie.',
        you_cannot_modify_your_badges: 'Vous ne pouvez pas modifier vos badges car votre dernière modification date de moins de 30 jours.',

        edit_profile: 'Éditer profile',
        edit: 'Modifier',
        profile_title: 'Titre du profil',
        profile_description: 'Description du profil',
        profile_email: 'Email du profil',
        web_site: 'Site web',

        subscribe_to_email_notification: 'Se désabonner au notification par mail',
        yes: 'Oui',
        no: 'Non',
        security_and_password: 'Sécurité et mot de passe',
        balance: 'Solde',
        subscriptions: 'Abonnements',

        current_password: 'Mot de passe actuel',
        new_password: 'Noueveau mot de passe',
        confirmation_of_new_password: 'Confirmation du nouveau mot de passe',
        your_current_password_is_incorrect: 'Votre mot de passe actuel est incorrect.',
        your_password_has_been_updated: 'Votre mot de passe a été mis à jour.',

        coming_soon: 'Bientôt disponible.',
        reload: 'Recharger',
        collect: 'Encaisser',
        no_transactions_available: 'Aucune transaction disponible',

        buying_coins: 'Digital Gifts',
        verified_account: 'Compte vérifié',
        premium_account: 'Compte premium',
        sponsored_opinion: 'Opinion sponsorisée',
        send_gift_to: 'Envoi de gift à',
        gift_received_from: 'Gift reçu de',
        monthly_subscription: 'Souscription mensuelle',
        annual_subscription: 'Souscription annuelle',

        // create opinion
        create_opinion: 'Créer une opinion',
        activate_vote: 'Activer le vote',
        enter_your_opinion: 'Entrez votre opinion',
        publishing_in_progress: 'Publication en cours...',
        add_to_your_opinion: 'Ajouter à votre opinion',
        publish: 'Publier',
        choice: 'Choix',
        mandatory: 'obligatoire',
        you_must_add_a_description_to_your_opinion: 'Vous devez ajouter une description à votre opinion.',
        the_first_two_choices_are_mandatory: 'Les deux premiers choix sont obligatoires.',
        video_too_large_please_select_a_video_less_than_50mb: 'Video trop volumineuse, veuillez sélectionner une vidéo de moins de 50 mb.',
        coming_soon: 'Bientôt disponible.',

        mute: 'Masquer',
        unmute: 'Démasquer',
        report: 'Signaler',
        download: 'Télécharger',
        delete: 'Supprimer',
        available: 'Disponible',
        unavailable: 'Indisponible',
        sorry: 'Désolé',

        // REPORT
        opinionTitle: "l'opinon",
        commentTitle: 'le commentaire',
        conversationTitle: 'la conversation',
        reportOpinion: {
            sexualContent: 'Contenu à caractère sexuel',
            violent: 'Contenu violent ou répugnant',
            hateful: 'Contenu haineux ou abusif',
            harassment: 'harcèlement ou intimidation',
            harmful: 'Actes nuisibles ou dangereux',
            childAbuse: 'Maltraitance',
            infrigeRight: 'Viole mes droits',
            terrorism: 'Favorise le terrorisme',
            spam: 'Spam ou misleadine',
            other: 'Autres',
        },
        reportComment: {
            commercial: 'Contenu commercial indésirable ou spam',
            sexual: 'Pornographie ou matériel sexuellement explicite',
            childAbuse: 'Maltraitance',
            hateSpeech: 'Discours haineux ou violence graphique',
            harassment: 'harcèlement ou intimidation',
        },
        report: 'Signaler',
        other: 'Autres',

        responseReportTitle:
            'Nous sommes désolés de cette mauvaise expérience sur MyOpinion.',
        responseReportDescription:
            'Votre signalement a été envoyé avec succès. Nous allons le traiter et prendre les actions adéquates si nécessaires.',

        responseOpinionTitle: 'Opinion signalée',
        responseOpinionDescription:
            'Nous allons analyser et prendre les actions nécessaires.',

        responseCommentTitle: 'Commentaire signalé',
        responseCommentDescription:
            'Nous allons analyser et prendre les actions nécessaires.',

        responseConversationTitle: 'Conversation signalée',
        responseConversationDescription:
            'Nous allons analyser et prendre les actions nécessaires.',

        enter_the_reason: 'Entrez la raison...',


        opinion_for_android: 'Opinion pour Android',
        opinion_for_ios: 'Opinion pour IOS',
        opinion_for_huawei: 'Opinion pour Huawei',
        opinion_for_web: 'Opinion pour Web',
        opinion_for: 'Opinion pour',

        search: 'Recherche',
        people: 'Personnes',
        search_by_badge: 'Recherche par badge',
        search_by_category: 'Recherche par catégorie',

        my_agora: 'Mes Agoras',
        private_agora: 'Agora privée',
        public_agora: 'Agora public',
        agora_name: 'Nom de l\'Agora',
        agora_description: 'Description de l\'Agora',
        my_requests: 'Mes demandes',
        my_invites: 'My invites',
        create_agora: 'Créer une Agora',
        create: 'Créer',
        title: 'Titre',
        participate: 'Participer',
        agora_you_might_be_interested_in: 'Des Agoras qui pourraient vous intéresser',
        show_your_last_seen:  'Afficher votre dernière connexion',
        hide_your_last_seen: 'Masquer votre dernière connexion',
        delete_all_messages: 'Supprimer tous les messages',
        write_your_message: 'Écrivez votre message',
        publish_in_the_agora: 'Publier dans l\'agora',
        enter_your_group_information:'Entrez les informations de votre Agora.',
        request_to_participate: 'Demande de participation',
        list_of_members: 'Liste des membres',

        people_you_match: 'Des personnes qui vous correspondent',
        member: 'Membre',
        members: 'Membres',
        view_all: 'Voir tout',



          // ADD PAGE
          my_accounts: 'Mes comptes',
          add_an_account: 'Ajouter un compte',
          login_to: 'Se connecter à',
          delete_this_account: 'Supprimer ce compte',
          this_account_has_already_been_added: 'Cet compte à déjà été ajouté.',
  
  
          // CREATE PAGE
          my_pages: 'Mes pages',
          create_a_page: 'Créer une page',
          create_a_page_description: 'Vos collègues accèdent à votre Page pour en savoir plus sur vous. Veuillez entrer toutes les informations dont ils pourraient avoir besoin.',
          pageName: 'Nom de la page',
          pageNameError: 'Entrez le nom de la page.',
          pageNickname: 'Pseudonyme de la page',
          pageNicknameError: 'Entrez le pseudonyme de la page.',
          descriptionOfThePage: 'Description de la page.',
          descriptionOfThePageError: 'Entrez la description de la page.',
          emailOfThePage: 'Email de la page',
          emailOfThePageError: 'Entrez l\'Email de la page.',
          country: 'Pays',
          countryError: 'Sélectionnez un pays.',
          phone: 'Téléphone',
          category: 'Categorie',
          categories: 'Catégories',
          webSite: 'Site web',
          createPage: 'Créer une page',
          myPages: 'Mes pages',
          pageCreated: 'Page créée',
          pageCreatedSuccessfully: 'Page créée avec succès.',
          thisNicknameIsAlreadyInUse: "Ce pseudonyme est déjà utilisé.",
          loginTo: 'Se connecter à',
  
  
          // PAGE MANAGE
          pageSetting: 'Paramètres de la page',
          inviteAnAdministrator: 'Inviter un administrateur',
          inviteAnAdministratorDesc: 'Envoyer une proposition de modération à un utilisateur.',
          listOfModerationRequests: 'Liste des demandes de modérations',
          listOfModerationRequestsDesc: 'Gestion des demandes de modérations.',
          community_Rules_and_Privacy_Policies: 'Règles communautaires et politiques de confidentialité',
  
          noModerationRequestAvailable: 'Aucune demande de modération disponible',
          noUserFound: 'Aucun utilisateur trouvé, lancez une recherche',
          selectARole: 'Sélectionnez un rôle.',
          inviteConfirmMessage: 'Souhaitez-vous envoyer une demande de moderation  à cet utilisateur ?',
          inviteSentMessageTitle: 'Demande de modération envoyée',
          inviteSentMessageDesc: 'Demande de modération envoyée avec succès.',
          inviteErrorMessage: 'Envoyer une demande de modération un utilisateur.',
          invited: 'Invité(e)',
          invite: 'Inviter',
          confirm: 'Confirmer',
          cancel: 'Annuler',
          delete: 'Supprimer',
          deleteRequest: 'Supprimer demande',
          deleteRequestDesc: 'Voulez-vous vraiment supprimer cette demande ?',
  
          moderationConfirmMessage: 'vous a envoyé une demande de moderation, souhaitez-vous être un modérateur de cette page ?',
          moderationRequestAccepted: 'Demande de moderation acceptée',
          moderationRequestAcceptedDesc: 'Demande de moderation acceptée avec succès.',
  
          no_data_available: 'Aucune donnée disponible',
  
    },
}

export default en;