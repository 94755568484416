import React, { useEffect, useState } from 'react'
import './OpinionDetails.style.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { api } from '../../api/api'
import Opinion from '../../components/Opinion/Opinion'
import Comment from '../../components/Comment/Comment'
import LoadingPage from '../../components/LoadingPage/LoadingPage'
import EmptyPage from '../../components/EmptyPage/EmptyPage'
import HelmetSeo from '../../components/HelmetSeo/HelmetSeo'


const OpinionDetails = () => {

    const {
        ago_id,
        sond_id
    } = useParams();

    const controller = new AbortController();
    const signal = controller.signal;


    const { t } = useTranslation()
    const { data: authData } = useAuthReducer()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const [opinion, setOpinion] = useState({})
    const [comments, setComments] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)



    useEffect(() => {
        fetchOpinion()
        fetchComments()
        return () => controller.abort();
    }, [])


    //  FETCH OPINION
    const fetchOpinion = async () => {
        try {
            console.log('Response opinion details:', sond_id + '...')
            const request = await api(`api/opinions/${sond_id}?page=${1}`, 'GET', null, { ...accessToken, ...signal });
            const response = await request.json()
            console.log('Response opinion details:', response)
            if (request?.status === 200) {
                if (response?.success) {
                    setOpinion(response.data)
                    updateOpinion(response?.data)
                    setIsEmpty(false)

                } else {
                    setIsEmpty(true)
                }
            }
            setIsLoading(false)
        } catch (error) {
            fetchOpinion()
            console.error('Error fetch opinion:', error);
        }
    }


    useEffect(() => {
        fetchComments()
        return () => controller.abort();
    }, [opinion?.sond_id])

    const fetchComments = async () => {
        try {
            if (opinion?.sond_id) {
                const request = await api(`api/opinions/allcomments/${opinion?.sond_id}`, 'GET', null, { ...accessToken, ...signal });
                const response = await request.json()
                console.log('Response all comments: ', response)
                setIsLoading(false)
                if (response?.success) {
                    setComments(response.comments)
                }
            }
        } catch (error) {
            fetchComments()
            console.error('Get opinion comments:', error.message);
        }
    }


    const updateOpinion = (data) => {
        setOpinion(data)
    }

    const deleteOpinion = (data) => {
        navigate(-1)
    }

    if (isLoading) {
        return (
            <div className='opinion-details'>
                <HelmetSeo
                    title={opinion?.sond_caption}
                    description={opinion?.sond_caption}
                    imageUrl={
                        opinion?.sond_type === 'image'
                            ? opinion?.sond_picture
                            : opinion?.sond_thumbnail
                    }
                />
                <Header title='opinion' goBack={() => navigate(-1)} />
                <LoadingPage text={t('loading_opinion')} />
            </div>
        )
    }

    if (isEmpty) {
        return (
            <div className='opinion-details'>
                <HelmetSeo
                    title={opinion?.sond_caption}
                    description={opinion?.sond_caption}
                    imageUrl={
                        opinion?.sond_type === 'image'
                            ? opinion?.sond_picture
                            : opinion?.sond_thumbnail
                    }
                />
                <Header title='opinion' goBack={() => navigate(-1)} />
                <EmptyPage text={`${t('sorry')}, opinion ${t('available').toLowerCase()}`} />
            </div>
        )
    }

    return (
        <div className='opinion-details'>
            <HelmetSeo
                title={opinion?.sond_caption}
                description={opinion?.sond_caption}
                imageUrl={
                    opinion?.sond_type === 'image'
                        ? opinion?.sond_picture
                        : opinion?.sond_thumbnail
                }
            />

            <Header title='opinion' goBack={() => navigate(-1)} />

            <div className='content-box'>

                {opinion &&
                    <Opinion
                        key={0}
                        index={0}
                        opinion={opinion}
                        updateOpinion={updateOpinion}
                        deleteOpinion={deleteOpinion}
                        handleMuteUser={deleteOpinion}
                        sourceFrom={'ViewOpinion'}
                    />
                }

                {opinion && comments.length !== 0 ?
                    comments.map((comment, index) => {
                        return (
                            <div>
                                <Comment
                                    key={index}
                                    user={comment?.user}
                                    com_id={comment?.com_id}
                                    com_mute={comment?.com_mute}
                                    com_parent={comment?.com_parent}
                                    com_sond={comment?.com_sond}
                                    com_text={comment?.com_text}
                                    com_type={comment?.com_type}
                                    com_user={comment?.com_user}
                                    attachment={comment?.attachment}
                                    soutiens={comment?.soutiens}
                                    soutiens_count={comment?.soutiens_count}
                                    subcomments={comment?.subcomments}
                                    subcomments_count={comment?.subcomments_count}
                                    com_date_created={comment?.com_date_created}
                                    sourceFrom={'ViewOpinion'}
                                />

                                {comment?.subcomments.map((subcomment, index) => {
                                    return (
                                        <div style={{ marginLeft: '55px' }}>
                                            <Comment
                                                key={index}
                                                user={subcomment?.user}
                                                com_id={subcomment?.com_id}
                                                com_mute={subcomment?.com_mute}
                                                com_parent={comment?.com_id}
                                                com_sond={subcomment?.com_sond}
                                                com_text={subcomment?.com_text}
                                                com_type={subcomment?.com_type}
                                                com_user={subcomment?.com_user}
                                                attachment={subcomment?.attachment}
                                                soutiens={subcomment?.soutiens}
                                                soutiens_count={subcomment?.soutiens_count}
                                                subcomments={subcomment?.subcomments}
                                                subcomments_count={subcomment?.subcomments_count}
                                                com_date_created={subcomment?.com_date_created}
                                                sourceFrom={'ViewOpinion'}
                                            />
                                        </div>
                                    )
                                })
                                }
                            </div>
                        )
                    }) : null}

            </div>

        </div>
    )
}

export default OpinionDetails
