import React, { useEffect, useState } from 'react'
import './Search.style.css'
import { MdAddBox, MdOutlineKeyboardBackspace } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../api/api'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { IoSearchSharp } from 'react-icons/io5'
import { color } from '../../hooks/Utils/color'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Spinner } from 'react-bootstrap'
import ProfileCard from '../../components/ProfileCard/ProfileCard'
import Header from '../../components/Header/Header'
import { useTranslation } from 'react-i18next'

const Search = () => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authData } = useAuthReducer()
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')
    const MAX_LENGTH = 250;

    const [searchUser, setSearchUser] = useState([])
    const [searchOpinion, setSearchOpinion] = useState([])
    const [searchAgora, setSearchAgora] = useState([])
    const [searchPage, setSearchPage] = useState([])

    const [tabIndex, setTabIndex] = useState(0);

    const goToProfile = (user_username) => {
        navigate(`/profiles/user/${user_username}`)
    }

    const goToViewOpinion = (agora_id, spnd_id) => {
        navigate(`/opinion/select/${agora_id ? agora_id : 0}/${spnd_id}`)
    }

    const goToSearchBadge = () => {
        navigate(`/search-badge`)
    }

    const goToSearchCategory = () => navigate('/search-category')
    

    const handleSearch = async (e) => {
        try {
            e.preventDefault();
            if (!search) {
                return
            }
            setIsLoading(true)
            const body = { search }
            const request = await api('api/search/search', 'POST', body, accessToken)
            const response = await request.json()
            // console.log('Response search:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setSearchUser(response?.search_person)
                    setSearchOpinion(response?.search_opinion)
                    setSearchAgora(response?.search_agora)
                    setSearchPage(response?.search_page)
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('Error search:', error)
        }
    }



    return (
        <div className='search'>
            <Header title={t('search')} goBack={() => navigate(-1)} />
            <form onSubmit={(e) => handleSearch(e)}>
                <div className='header'>
                    <input
                        type='search'
                        placeholder={`${t('search')}...`}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className='search-input'
                    />
                    <button onClick={handleSearch} className='search-button'>
                        {!isLoading ? <IoSearchSharp size={20} color='white' /> :
                            <Spinner
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                                variant='light'
                            />}
                    </button>
                </div>
            </form>

            <div style={{ height: '84vh'}}>

                <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px' }}>

                    <button onClick={goToSearchBadge} style={{maxWidth: '40%', backgroundColor: color.primary, borderRadius: '25px', paddingBottom: '3px', cursor: 'pointer' }}>
                        <span style={{ fontSize: '13px', fontWeight: '600', color: 'white', marginLeft: '5px', marginRight: '5px' }}>
                            {t('search_by_badge')}
                        </span>
                    </button>

                    <button onClick={goToSearchCategory} style={{maxWidth: '40%', backgroundColor: color.primary, borderRadius: '25px', paddingBottom: '3px', cursor: 'pointer' }}>
                        <span style={{lineHeight: '2px', fontSize: '12px', fontWeight: '600', color: 'white', marginLeft: '5px', marginRight: '5px' }}>
                            {t('search_by_category')}
                        </span>
                    </button>

                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', paddingRight: '5%', borderBottom: '1px #f1f1f1 solid' }}>

                    <div onClick={() => setTabIndex(0)} className='tab'>
                        <b style={{ fontSize: '15px', textAlign: "center", color: tabIndex === 0 ? color.primary : '#808080' }}>
                            {t('people')}{' '}
                            <span style={{ backgroundColor: tabIndex === 0 ? color.primary : '#808080', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', color: 'white' }}>
                                {searchUser.length}
                            </span>
                        </b>
                    </div>
                    <div onClick={() => setTabIndex(1)} className='tab'>
                        <b style={{ fontSize: '15px', textAlign: "center", color: tabIndex === 1 ? color.primary : '#808080' }}>
                            Opinions{' '}
                            <span style={{ backgroundColor: tabIndex === 1 ? color.primary : '#808080', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', color: 'white' }}>
                                {searchOpinion.length}
                            </span>
                        </b>
                    </div>
                    <div onClick={() => setTabIndex(2)} className='tab'>
                        <b style={{ fontSize: '15px', textAlign: "center", color: tabIndex === 2 ? color.primary : '#808080' }}>
                            Agoras{' '}
                            <span style={{ backgroundColor: tabIndex === 2 ? color.primary : '#808080', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', color: 'white' }}>
                                {searchAgora.length}
                            </span>
                        </b>
                    </div>
                    <div onClick={() => setTabIndex(3)} className='tab'>
                        <b style={{ fontSize: '15px', textAlign: "center", color: tabIndex === 3 ? color.primary : '#808080' }}>
                            Page{' '}
                            <span style={{ fontSize: '14px', backgroundColor: tabIndex === 3 ? color.primary : '#808080', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', color: 'white' }}>
                                {searchPage?.length}
                            </span>
                        </b>
                    </div>
                </div>

                {tabIndex === 0 &&
                    <div className='containerResult' style={{ overflowY: 'scroll', height: '71vh' }}>
                        {searchUser.map((user, index) => {
                            return (
                                <div key={index?.toString()}>
                                    <ProfileCard
                                        user={user}
                                        pictureSize={60}
                                    />
                                </div>
                            )
                        })}
                    </div>
                }

                {tabIndex === 1 &&
                    <div className='containerResult' style={{ overflowY: 'scroll', height: '71vh' }}>
                        {searchOpinion.map((opinion, index) => {
                            return (
                                <div key={index?.toString()} onClick={() => goToViewOpinion(opinion?.agora?.ago_id, opinion?.sond_id)} style={{ cursor: 'pointer', borderBottom: '5px #f1f1f1 solid', padding: '15px' }}>
                                    <ProfileCard
                                        user={opinion?.user}
                                        pictureSize={50}
                                    />
                                    <p>
                                        {`${opinion?.sond_caption.substring(0, MAX_LENGTH)}...`}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                }

                {tabIndex === 2 &&
                    <div className='containerResult' style={{ overflowY: 'scroll', height: '71vh' }}>
                        {searchAgora.map((agora, index) => {
                            return (
                                <div key={index?.toString()} style={{ display: 'flex', padding: '10px' }}>
                                    <div>
                                        <img src={agora?.ago_picture} width={50} height={50} alt='' style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <b>{agora?.ago_title}</b>
                                        <p>{`${agora?.ago_presentation.substring(0, 100)}...`} </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }

                {tabIndex === 3 &&
                    <div className='containerResult' style={{ overflowY: 'scroll', height: '71vh' }}>
                        {searchPage.map((page, index) => {
                            return (
                                <div key={index?.toString()}>
                                    <ProfileCard
                                        user={page}
                                        pictureSize={60}
                                    />
                                </div>
                            )
                        })}
                    </div>
                }

            </div>
        </div>
    )
}

export default Search