import React, { useEffect, useState } from 'react'
import { AiFillLike, AiLike, AiOutlineLike } from 'react-icons/ai'
import { api } from '../../../../api/api';
import { useTranslation } from 'react-i18next';
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks';
import useNotificationPush from '../../../../hooks/useNotificationPush/useNotificationPush';

const LikeCounter = ({
    sond_id,
    sond_user,
    sond_caption,
    soutiens,
    soutiens_count,
    setShowUnauthModal,
    sourceFrom
}) => {

    // STATE
    const { t } = useTranslation()
    const sendNotification = useNotificationPush()
    const accessToken = useAccessToken()
    const { data: AuthData } = useAuthReducer()
    const [likeCount, setLikeCount] = useState(soutiens_count)
    const [isLiked, setLisLiked] = useState(soutiens?.length !== 0)


    useEffect(() => {
        soutiens.some(item => item)
    }, [])


    // handleLike
    const handleLike = () => {
        if (sourceFrom == 'Feed') {
            setShowUnauthModal(true)
            return
        }
        if (isLiked) {
            setLisLiked(false)
            setLikeCount(likeCount => likeCount - 1)
        } else {
            setLisLiked(true)
            setLikeCount(likeCount => likeCount + 1)
        }
        onLike()
    }


    const onLike = async () => {
        if (soutiens?.length === 0) {
            try {
                const body = {
                    sout_opinion: sond_id
                }
                const request = await api('api/soutiens', 'POST', body, accessToken)
                const response = await request.json()
                console.log('response of like opinion', response)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        // updateOpinion(response.opinion)
                        // if (sond_user !== AuthData?.user?.user_id) {
                        //     sendNotification(sond_user, {
                        //         title: `${AuthData?.user?.user_surname} ${AuthData?.user?.user_name} a liké votre opinion:`,
                        //         body: sond_caption,
                        //         url: `https://myopinion.ci/opinion/select/0/${sond_id}`,
                        //     })
                        // }

                        sendPushNotification(sond_user)
                    }
                }
            } catch (error) {
                throw new Error(error.message)
            }
        } else {
            try {
                const request = await api(`api/soutiens/${soutiens[0].sout_id}`, 'DELETE', null, accessToken,)
                const response = await request.json()
                console.log('Response of remove a like:', response)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        // updateOpinion(response.opinion)
                    }
                }
            } catch ({ message }) {
                throw new Error(message)
            }
        }
    }


    // Can use this function below OR use Expo's Push Notification Tool from: https://expo.dev/notifications
    async function sendPushNotification() {
        if (sond_user === AuthData?.user?.user_id) {
            return
        }
        const request = await api(`api/user/get-notifications-token/${sond_user}`, 'GET', null, accessToken)
        const response = await request.json()
        console.log('Response fetch notification token:', response)
        if (response?.success) {
            const message = {
                to: response?.user_notif_token?.user_notif_token,
                sound: 'default',
                title: `${AuthData?.user?.user_surname} ${AuthData?.user?.user_name} a liké votre opinion:`,
                body: sond_caption,
                data: { url: `https://myopinion.ci/opinion/select/0/${sond_id}` },
            };

            const responsePush = await fetch('https://exp.host/--/api/v2/push/send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Accept-encoding': 'gzip, deflate',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(message),
            });
            console.log('Response send push notification:', responsePush)
        }
    }



    return (
        <div onClick={handleLike} className='like-counter'>
            <button style={{ display: 'flex', alignItems: 'center' }}>
                {isLiked ?
                    <AiFillLike size={26} color={isLiked ? '#1880a1' : '#808080'} />
                    :
                    <AiOutlineLike size={26} color={isLiked ? '#1880a1' : '#808080'} />
                }
                <span style={{ color:  likeCount === 0 ? '#808080' : '#1880a1', fontSize: '14px', fontWeight: '600', margin: '0px', marginLeft: '3px' }}>
                    {likeCount}
                </span>
            </button>
        </div>
    )
}

export default LikeCounter