import React, { useCallback, useEffect, useState } from 'react'
import styles from './MessageCard.module.css'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import moment from 'moment';
import 'moment/locale/fr';
import { ReactTinyLink } from 'react-tiny-link';
import Video from './Video/Video';
import Image from './Image/Image';
import Audio from './Audio/Audio';
import { BiCheckDouble, BiTime, BiCheck } from 'react-icons/bi';
import { FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';
import { Alert, Button, IconButton, Menu, MenuItem, Snackbar } from '@mui/material';
import ProfilePicture from 'components/ProfilePicture/ProfilePicture';
import SondDocs from 'components/Opinion/OpinionBody/SondDocs/SondDocs';
import { useTranslation } from 'react-i18next';
import { api } from 'api/api';



const MessageCard = ({
    user_id,
    received,
    message,
    messages = [],
    setMessages = () => null
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const isMyMessage = message?.sender_id === authData?.user?.user_id && message?.receiver_id !== user_id
    const timeAgo = moment(message.chat_messages_datetime).locale(authData?.language).fromNow();
    const [link, setLink] = useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] = useState('');
    const handleClickSnack = (message) => { 
        setMessageSnack(message)
        setOpenSnack(true) 
    };
    const handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnack(false);
    };

    useEffect(() => {
        const link = findLinkInText(message?.chat_messages_text);
        setLink(link)
    }, [message?.chat_messages_text])


    const handleCopieText = () => {
        handleClose()
        if (message?.chat_messages_text && message?.chat_messages_text !== 'null') {
            navigator.clipboard.writeText(message?.chat_messages_text)
                .then(() => { })
                .catch(err => console.error('Failed to copy: ', err));
        }
    }

    const handleDelete = () => {
        handleClose()
        handleDeleteMessage()
        setMessages(messages?.filter(item => item?.chat_messages_id !== message?.chat_messages_id))
    }

    const findLinkInText = (text) => {
        if (!text) {
            return
        }
        const linkRegex = /(https?:\/\/[^\s]+)/;
        const linkMatch = text.match(linkRegex);
        return linkMatch ? linkMatch[0] : null;
    }


    const handleDeleteMessage = async () => {
        try {
            const request = await api(
                `api/messages/${message?.chat_messages_id}`,
                'DELETE',
                null,
                accessToken,
            )
            const response = await request.json()
            // console.log('Response message:', response)
            handleClickSnack(
                i18n.language === 'en' ? 
                'Message successfully deleted.' :
                'Message supprimé avec succès.'
            )
            if (request.ok && request.status === 200) {
                if (response.success) {
                   
                }
            }
        } catch ({ message }) {
            // console.log(message)
            throw new Error(message)
        }
    }

    const AttachmentCard = useCallback(({ attachment }) => {
        if (attachment.attach_type === 'document') {
            return (
                <div style={{ marginLeft: '-25px' }}>
                    <SondDocs
                        uri={attachment?.attach_url}
                        style={{ width: '20%', border: '1px #c9c9c9 solid' }}
                        textStyle={{
                            color: isMyMessage ? 'white' : 'black',
                            fontSize: '13px'
                        }}
                    />
                </div>
            )
        }
        if (attachment.attach_type === 'audio') {
            return (
                <Audio attachment={attachment} />
            )
        }
        if (attachment.attach_type === 'image') {
            return (
                <Image attachment={attachment} />
            )
        }
        if (attachment.attach_type === 'video') {
            return (
                <Video attachment={attachment} />
            )
        }
    }, []) 

    const messageOptions = () => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {/* <MenuItem onClick={handleClose}>Reponse</MenuItem> */}
                <MenuItem onClick={handleCopieText}>Copier</MenuItem>
                {isMyMessage &&
                    <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
                }
            </Menu>
        )
    }


    if (isMyMessage) {
        return (
            <div className={styles.senderMessageContainer} >
                <div style={{ textAlign: 'right' }}>
                    <p className={styles.senderMessageText}>

                        {message?.message_parent?.chat_messages_text &&
                            <p className='text' style={{ fontSize: '13px', color: '#f1f1f1', borderBottom: '1px #f1f1f1 solid', margin: '0px' }}>
                                {message?.message_parent?.chat_messages_text}
                            </p>
                        }

                        {message?.attachments.length !== 0 &&
                            message?.attachments.map((attachment, index) => {
                                return (
                                    <div key={index?.toString()} style={{ width: '25vw', marginTop: '5px' }}>
                                        <AttachmentCard attachment={attachment} />
                                    </div>
                                )
                            })
                        }

                        {(message?.chat_messages_text && message?.chat_messages_text !== 'null') &&
                            message?.chat_messages_text
                        }

                        {link &&
                            <ReactTinyLink
                                cardSize="small"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url={link}
                            />
                        }

                        <div className={styles.senderMessagetimestamp}>
                            {timeAgo}
                            <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                style={{ marginRight: '-15px', height: '15px' }}>
                                <FiMoreHorizontal size={18} color='white' />
                            </IconButton>
                            {isMyMessage && message?.chat_messages_status === 'pending' &&
                                <BiTime size={16} style={{ marginLeft: '10px' }} />
                            }
                            {isMyMessage && message?.chat_messages_status === 'no' &&
                                <BiCheck style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                            }
                            {isMyMessage && message?.chat_messages_status === 'yes' &&
                                <BiCheckDouble style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                            }
                        </div>
                    </p>
                </div>
                {messageOptions()}
            </div >
        )
    } else {
        return (
            <div className={styles.receiverMessageContainer} >
                <ProfilePicture
                    user={received}
                    uri={received?.profile?.prof_picture}
                    size={35}
                />
                <div style={{ textAlign: 'left' }}>
                    <p className={styles.receiverMessageText}>

                        {message?.message_parent?.chat_messages_text &&
                            <p className='text' style={{ fontSize: '13px', color: '#5c5b5b', borderBottom: '1px #5c5b5b solid', margin: '0px' }}>
                                {message?.message_parent?.chat_messages_text}
                            </p>
                        }

                        {message?.attachments.length !== 0 &&
                            message?.attachments.map((attachment, index) => {
                                return (
                                    <div key={index?.toString()} style={{ width: '25vw', marginTop: '5px' }}>
                                        <AttachmentCard attachment={attachment} />
                                    </div>
                                )
                            })
                        }

                        {(message?.chat_messages_text && message?.chat_messages_text !== 'null') &&
                            message?.chat_messages_text
                        }

                        {link &&
                            <ReactTinyLink
                                cardSize="small"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url={link}
                            />
                        }

                        <div className={styles.receiverMessagetimestamp}>
                            {timeAgo}
                            <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                style={{ marginRight: '-10px', height: '15px', }}>
                                <FiMoreHorizontal size={18} color='#414141' />
                            </IconButton>
                        </div>
                    </p>
                </div>
                {messageOptions()}

                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                    <Alert onClose={handleCloseSnack} severity={"success"} sx={{ width: '100%' }}>
                        {messageSnack}
                    </Alert>
                </Snackbar>
            </div >
        )
    }
}

export default MessageCard