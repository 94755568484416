import React, { useState } from 'react'
import { FaRegPlayCircle } from 'react-icons/fa'

const SondVideo = ({
    uri,
    thumbnail,
    width = '100%',
    height = '400px',
}) => {
    
    const [isLoaded, setIsLoaded] = useState(false)

    if (!isLoaded) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `url(${thumbnail}) no-repeat center`, 
                backgroundColor: 'black', 
                width: '100%', 
                height: height}}>
                <div onClick={() => setIsLoaded(true)} style={{cursor: 'pointer'}}>
                    <FaRegPlayCircle size={60} color='white' />
                </div>
            </div>
        )
    } else {
        return (
            <div className='sond-video' style={{ width, height }}>
                <video width="100%" height="100%" controls >
                    <source src={uri} />
                </video>
            </div>
        )
    }
}

export default SondVideo;