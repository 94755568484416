import React, { useEffect } from 'react'
import styles from './SelectPageCat.module.css'
import { Autocomplete, ListItemText, TextField } from '@mui/material'
import Select from 'react-select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks';
import { api } from 'api/api';
import { AiOutlineClose } from 'react-icons/ai';

const SelectPageCat = ({
    value = '',
    setValue = () => null,
    style
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()


    const [options, setOptions] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [text, setText] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const request = await api('api/page_categories', 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch categories page response:', response)
                if (request.status === 200) {
                    if (response.success) {
                        const data = response?.data
                            .sort((a, b) => i18n.language === 'en' ?
                                a.cat_name_en.localeCompare(b.cat_name_en) :
                                a.cat_name.localeCompare(b.cat_name)
                            ).map(item => ({
                                value: item?.cat_id,
                                label: i18n.language === 'en' ? item?.cat_name_en : item?.cat_name
                            }))
                        setOptions(data)
                    }
                }
            } catch (error) {
                console.error('Create new page error:', error?.data || error?.message);
            }
        }
        fetchData()
    }, [])


    return (
        <div style={style}>
            <Autocomplete
                disablePortal
                key={(item, index) => index.toString()}
                options={options || []}
                renderInput={(params) => <TextField {...params} label={`${t('search')?.replaceAll('.', '')} ${t('categories')?.toLowerCase()}`} />}
                onChange={(event, value) => {
                    setValue(value?.value)
                    setSelectedItems(value)
                }}
                size='small'
            />
        </div>
    )
}

export default SelectPageCat