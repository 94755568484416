import React, { useCallback } from 'react'
import styles from './ConversationCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { color } from 'hooks/Utils/color'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'


const ConversationCard = ({ user }) => {

    const navigate = useNavigate()

    const goToConversation = () => {
        navigate(`/one-to-one/${user.user_id}`)
    }

    return (
        <div className={styles.conversation} onClick={goToConversation}>
            <ProfileCard
                user={user}
                pictureSize={50}
                sourceFrom={'OneToOneConversation'}
            />
             <span className={styles.badge} style={{ backgroundColor: user?.sent_messages_count > 0 ? '#b30303' : color.grayLight, }}>
                {user?.sent_messages_count > 9 ? '9+' : user?.sent_messages_count}
            </span>
        </div>
    )
}

export default ConversationCard