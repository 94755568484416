import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineDisconnect } from 'react-icons/ai'
import { FaUserTimes } from 'react-icons/fa'
import { MdPersonAddAlt1 } from 'react-icons/md'
import { TbPlugConnected } from 'react-icons/tb'
import { api } from '../../../api/api'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import ProfileCard from '../../ProfileCard/ProfileCard'

const Received = ({
    item,
    user,
    refresh
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCancel, setIsLoadingCancel] = useState(false)


    const approveBuddy = async () => {
        try {
            setIsLoading(true)
            const body = { user_id: item?.user_id }
            const request = await api(
                'api/profile/approve-request-buddy',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {
                    refresh()
                    //   sendNotification(user_id, {
                    //     title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //     body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a accepté votre demande.`,
                    //     url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //   })
                }
            }
            setIsLoading(false)
        } catch ({ message }) {
            setIsLoading(false)
            throw new Error(message)
        }
    }


    const cancelBuddy = async () => {
        try {
            setIsLoadingCancel(true)
            const body = { user_id: item?.user_id }
            const request = await api(
                'api/profile/request-cancel-buddy',
                'DELETE',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {
                    refresh()
                }
            }
            setIsLoadingCancel(false)
        } catch ({ message }) {
            setIsLoadingCancel(false)
            throw new Error(message)
        }
    }




    const renderButton = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                    onClick={!isLoading && approveBuddy}
                    className='button'
                    style={{
                        width: '140px',
                        fontSize: "13px",
                        backgroundColor: '#1880a1',
                        color: "white",
                    }}>

                    {!isLoading ?
                        <>
                            <MdPersonAddAlt1 size={18} style={{ marginRight: '5px' }} />
                            {t('approve')}
                        </>
                        :
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mt-1 mb-1"
                        />
                    }

                </button>

                <button
                    onClick={cancelBuddy}
                    className='button'
                    style={{
                        width: '135px',
                        backgroundColor: 'white',
                        fontSize: "13px",
                        color: "red",
                        fontWeight: '600',
                        border: '1.8px red solid'
                    }}>

                    {!isLoadingCancel ?
                        <>
                            <FaUserTimes size={18} style={{ marginRight: '5px' }} />
                            {t('cancel')}
                        </>
                        :
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant="danger"
                        />
                    }

                </button>
            </div>
        )

    }


    return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{ width: '70%' }}>
                <ProfileCard
                    user={item}
                    pictureSize={55}
                />
            </div>
            {authData?.user?.user_id == user?.user_id &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '30%', paddingRight: '15px' }}>
                    {renderButton()}
                </div>
            }
        </div>
    )
}

export default Received