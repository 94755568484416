import React from 'react'
import './ButtonSubmit.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'react-bootstrap'

function ButtonSubmit({
    value = 'button',
    onClick,
    isLoading = false,
    loadBoostrapColor = 'light',
    style,
}) {

    if (!isLoading) {
        return (
            <input onClick={onClick} type={'submit'} value={value} style={style} className='button-submit' />
        )
    } else {
        return (
            <div className='button-submit' style={style}>
                <Spinner
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    variant={loadBoostrapColor}
                />
            </div>
        )
    }


}

export default ButtonSubmit