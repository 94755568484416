import React, { useEffect, useRef, useState } from 'react'
import styles from './OneToOneMessage.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks';
import { api } from '../../../api/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputEmoji from "react-input-emoji";
import { RiSendPlaneFill, RiUserUnfollowLine } from 'react-icons/ri'
import { RiAttachment2 } from 'react-icons/ri'
import axios from 'axios'
import { MdDeleteOutline, MdOutlineKeyboardBackspace } from 'react-icons/md';
import ProfileCard from '../../../components/ProfileCard/ProfileCard';
import { color } from '../../../hooks/Utils/color';
import Compressor from 'compressorjs'
import { useTranslation } from 'react-i18next';
import ModelAlert from '../../../components/ModalAlert/ModelAlert';
import { IoIosCloseCircle } from 'react-icons/io';
import { Modal, Spinner } from 'react-bootstrap';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import Header from '../../../components/Header/Header';
import { FaMicrophone } from 'react-icons/fa';
import { AiFillCloseCircle, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { TfiMenu } from 'react-icons/tfi'
import { BiLockAlt } from 'react-icons/bi';
import useReducerAuthAction from '../../../hooks/ReducerHooksAction/useReducerAuthAction';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import useReducerOneToOneAction from 'hooks/ReducerHooksAction/useReducerOneToOneAction';
import MessageCard from './MessageCard/MessageCard';
import { access_extensions, audio_extensions, excel_extensions, image_extensions, powerpoint_extensions, video_extensions, word_extensions } from 'constants/formFiles';


const OneToOneMessage = () => {

    const controller = new AbortController();
    const signal = controller.signal;
    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
    const { REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, REFRESH_BUDDIES } = useReducerOneToOneAction()
    const { data: authData } = useAuthReducer()
    const { t } = useTranslation()
    const { user_id } = useParams()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const [userTchat, setUserTchat] = useState({});

    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMorePage, setHasMorePage] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [attachmentsPreview, setAttachmentsPreview] = useState([]);

    const [isBloqued, setIsBloqued] = useState(false);
    const [mute, setMute] = useState(false);
    const [friendMute, setFriendMute] = useState(false);
    const [showAlertVideo, setShowAlertVideo] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const refMessageBox = useRef(null)
    const [heightMessageBox, setHeightMessageBox] = useState(0)

    const [isVoiceMessage, setIsVoiceMessage] = useState(false)
    const [isLoadingRequest, setIsLoadingRequest] = useState(false)


    // RECORDE AUDIO
    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    let mediaRecorder = null;

    // GET HEIGTH OF MESSAGE BOX
    useEffect(() => {
        setHeightMessageBox(refMessageBox?.current?.clientHeight)
    }, [])


    const handleDataAvailable = (event) => {
        const file = new Blob([event.data], { type: 'audio/mp3' });
        const url = URL.createObjectURL(file);
        setAudioURL(url);

        const data = {
            file,
            width: null,
            height: null,
            duration: null,
            size: file.size,
            type: 'audio',
            thumbnail: null,
            extension: file.type.split("/")[1],
            mime_type: file.type
        }
        setAttachments(attachments => [...attachments, data])
    };

    const startRecording = () => {
        mediaRecorder.start();
        setRecording(true);
    };

    const stopRecording = () => {
        mediaRecorder.stop();
        setRecording(false);
    };

    const sendAudio = () => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                // console.log('Audio sent successfully');
            }
        };
        xhr.open('POST', 'your-server-url', true);
        xhr.send(audioURL);
    };



    useEffect(() => {
        fetchMessages()
        updateMessageStatut()
        const timerMessage = setInterval(() => {
            fetchUnreadMessages()
        }, 5000);
        return () => {
            clearInterval(timerMessage)
            controller.abort()
        }
    }, [])


    const fetchUnreadMessages = async () => {
        try {
            const request = await api(`api/one/${user_id}/unreadmessages`, 'GET', null, accessToken)
            const response = await request.json()
            // console.log('Response unread messages:', response.messages.length + ' messages')
            if (request.ok && request.status === 200) {
                setIsBloqued(response?.user_blocked)
                if (response?.user_blocked) {
                    deleteAllMessages()
                } else {
                    setMute(response?.auth_user_muted_conversation)
                    setFriendMute(response?.friend_user_muted_conversation)
                    if (response.messages.length !== 0) {
                        fetchMessages()
                        updateMessageStatut()
                    }
                }
            }
        } catch ({ message }) {
            console.log(message)
            throw new Error(message)
        }
    }

    const updateMessageStatut = async () => {
        try {
            const request = await api(`api/one/${user_id}/read`, 'POST', null, accessToken)
            const response = await request.json()
            // console.log('response update message statut:', response)
            fetchConversations()
        } catch ({ message }) {
            updateMessageStatut()
            throw new Error(message)
        }
    }


    const fetchMessages = async () => {
        try {
            // console.log('Fetching message...')
            const request = await api(`api/one/${user_id}?paginate&page=${1}`, 'GET', null, { ...accessToken, ...signal })
            const response = await request.json()
            // console.log('Response message:', response)
            setIsLoading(false)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setUserTchat({
                        user_surname: response?.user?.user_surname,
                        user_name: response?.user?.user_name,
                        user_username: response?.user?.user_username,
                        user_verified: response?.user?.user_verified,
                        user_gold: response?.user?.user_gold,
                        profile: {
                            prof_picture: response?.user?.profile?.prof_picture
                        },
                        connection_status: response?.user?.connection_status,
                        user_badge_business: response?.user?.user_badge_business,
                        user_badge_color: response?.user?.user_badge_color,
                        user_badge_food: response?.user?.user_badge_food,
                        user_badge_goal: response?.user?.user_badge_goal,
                        user_badge_hobby: response?.user?.user_badge_hobby,
                        user_match_value: response?.user?.user_match_value,
                    })
                    setIsBloqued(response?.user_blocked)
                    if (response?.user_blocked) {
                        deleteAllMessages()
                    } else {
                        if (response?.messages?.next_page_url) {
                            setHasMorePage(true)
                        } else {
                            setHasMorePage(false)
                        }
                        setPage(2)

                        if (response?.messages?.data?.length !== 0) {

                            setMessages(response?.messages?.data)

                            setMute(response?.auth_user_muted_conversation)
                            setFriendMute(response?.friend_user_muted_conversation)


                        }

                    }
                }
            }
            setIsLoading(false)
        } catch (error) {
            // fetchMessages()
            console.log('Error fetch message:', error)
        }
    }

    const loadMoreMessages = async () => {
        try {
            if (hasMorePage && !isLoading && !isLoadingMore) {
                setIsLoadingMore(true)
                const request = await api(`api/one/${user_id}?paginate&page=${page}`, 'GET', null, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response message:', response?.messages)
                setIsLoadingMore(false)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        setIsBloqued(response?.user_blocked)
                        if (response?.user_blocked) {
                            deleteAllMessages()
                        } else {
                            if (response?.messages?.next_page_url) {
                                setHasMorePage(true)
                            } else {
                                setHasMorePage(false)
                            }
                            setPage(page + 1)

                            if (response?.messages?.data?.length !== 0) {
                                setMessages([...messages, ...response?.messages?.data])

                                setMute(response?.auth_user_muted_conversation)
                                setFriendMute(response?.friend_user_muted_conversation)

                                setUserTchat({
                                    user_surname: response?.user?.user_surname,
                                    user_name: response?.user?.user_name,
                                    user_username: response?.user?.user_username,
                                    user_verified: response?.user?.user_verified,
                                    user_gold: response?.user?.user_gold,
                                    profile: {
                                        prof_picture: response?.user?.profile?.prof_picture
                                    },
                                    connection_status: response?.user?.connection_status,
                                    user_badge_business: response?.user?.user_badge_business,
                                    user_badge_color: response?.user?.user_badge_color,
                                    user_badge_food: response?.user?.user_badge_food,
                                    user_badge_goal: response?.user?.user_badge_goal,
                                    user_badge_hobby: response?.user?.user_badge_hobby,
                                    user_match_value: response?.user?.user_match_value,
                                })
                            }

                        }
                    }
                }
            }
        } catch (error) {
            // fetchMessages()
            console.log('Error fetch message:', error)
        }
    }


    const updateLastSeen = async () => {
        try {
            // console.log('Updating last seen:', authData?.user?.user_show_last_seen)
            const body = {
                user_show_last_seen: authData?.user?.user_show_last_seen ? false : true
            }
            const request = await api(`api/user/update-show-last-seen`, 'POST', body, accessToken)
            const response = await request.json()
            // console.log('Response updating last seen:', response)
            if (request.ok && request.status === 200) {
                if (response?.success) {
                    setShowMenu(false)
                    updateUserDetails()
                }
            }
        } catch (error) {
            setShowMenu(false)
            console.error('Error update last seen:', error);
        }
    }

    const updateUserDetails = async () => {
        try {
            const request = await api('api/get-user', 'GET', {}, accessToken)
            const response = await request.json()
            // console.log('Response fetch user:', response?.user.user_show_last_seen)
            if (request.ok && request.status === 200) {
                UPDATE_PROFILE(response?.user)
            }
        } catch (e) {
            updateUserDetails()
            console.warn('Error fetch user info:', e.message)
            throw new Error(e)
        }
    }


    const deleteAllMessages = async () => {
        try {
            const body = {
                user_id: user_id,
            }
            const request = await api(
                'api/one/delete-inbox',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            // console.log(response, 'response of delete message ')
            if (request.ok && request.status === 200) {
                if (response?.success) {
                    setShowMenu(false)
                    setMessages([])
                    if (isBloqued) {
                        navigate(-1)
                    }
                }
            }
        } catch (error) {
            setShowMenu(false)
            throw new Error(error.message)
        }
    }

    const blockBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/blocked',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            // console.log(response, 'response of BLOC ON ONE TO ONE PROFIL')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setIsBloqued(true)
                    deleteAllMessages()
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }



    const handleSendMessage = async () => {
        try {
            if (!message && attachments?.length === 0) {
                return
            }
            // chat_messages_datetime: "2023-08-14T11:54:32.000000Z"
            // chat_messages_id: 18
            // chat_messages_status: "yes"
            // chat_messages_text: "Coucou"
            // message_parent: null
            // message_parent_id: null
            // message_reponse: null
            // receiver_id: 2
            // sender_id: 4
            const timestamp = new Date().getTime()

            const new_message = {
                chat_messages_id: new Date().getTime(),
                sender_id: authData?.user?.user_id,
                receiver_id: parseInt(user_id),
                chat_messages_text: message,
                chat_messages_status: "pending",
                message_parent_id: new Date().getTime(),
                message_parent: null,
                attachments: attachments?.map(attach => ({
                    attach_created_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                    attach_duration: attach?.duration,
                    attach_extension: attach?.extension,
                    attach_height: attach?.height,
                    attach_id: timestamp,
                    attach_index: null,
                    attach_mime_type: attach?.mime_type,
                    attach_model_id: timestamp,
                    attach_model_type: "App\\Models\\ChatMessage",
                    attach_size: attach?.size,
                    attach_thumbnail: attach?.thumbnail,
                    attach_type: attach?.type,
                    attach_updated_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                    attach_url: attach?.uri,
                    attach_width: attach?.width,
                })),
                chat_messages_datetime: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            }
            setMessages([new_message, ...messages])

            // setIsLoadingRequest(true)
            const formData = new FormData();
            formData.append('receiver_id', user_id);
            formData.append('chat_messages_text', message);
            // formData.append('message_parent_id', messageParent?.id);

            if (attachments[0]?.type == 'document') {
                formData.append('attachments', 1);
                formData.append(`attachment_1`, attachments[0]?.file);
                formData.append(`attach_filename_1`, attachments[0]?.file?.name);
                formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
                formData.append(`attachment_type_1`, attachments[0]?.type);
                formData.append(`attachment_size_1`, attachments[0]?.size);
                formData.append(`attachment_extension_1`, attachments[0]?.name);
            } else if (attachments[0]?.type == 'audio') {
                formData.append('attachments', 1);
                formData.append(`attachment_1`, attachments[0]?.file);
                formData.append(`attachment_type_1`, attachments[0]?.type);
                formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
                formData.append(`attachment_extension_1`, attachments[0]?.extension);
            } else {
                formData.append('attachments', attachments?.length);
                attachments.forEach((attachment, index) => {

                    formData.append(`attachment_${index + 1}`, attachment?.file);
                    formData.append(`attach_filename_${index + 1}`, attachment?.file?.name);
                    formData.append(`attachment_type_${index + 1}`, attachment?.type);
                    formData.append(`attachment_mime_type_${index + 1}`, attachment?.mime_type);

                    if (attachment?.type !== 'audio') {
                        formData.append(`attachment_width_${index + 1}`, attachment?.width);
                        formData.append(`attachment_height_${index + 1}`, attachment?.height);
                        formData.append(`attachment_size_${index + 1}`, attachment?.size);
                    }
                    if (attachment?.type == 'video') {
                        formData.append(`attachment_thumbnail_${index + 1}`, attachment?.thumbnail);
                        formData.append(`attachment_duration_${index + 1}`, attachment?.duration);
                    }
                });
            }

            setMessage('')
            setAttachments([])
            setAttachmentsPreview([])

            const request = await axios.post(`${process.env.REACT_APP_API_URL}/api/sendMessage`, formData, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    console.log(Math.round((loaded / total) * 100), '% envoi...')
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            const response = await request?.data
            // console.log('Response send message:', response)
            if (request?.status === 200) {
                if (response?.success) {
                    setMessage('')
                    setAttachments([])
                    setAttachmentsPreview([])
                    fetchConversations()
                    fetchMessages()
                }
            }
        } catch (error) {
            // if (error.message === 'Network Error') {
            //     handleSendMessage()
            // } else {
            //     handleSendMessage()
            // }
            console.error(error.message);
        }
    }


    const fetchConversations = async () => {
        try {
            const request = await api(`api/my-messages?paginate&page=${1}`, 'GET', {}, accessToken)
            const response = await request.json()
            // console.log('Fetch conversations response:', response?.list?.data?.length)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    if (response?.list?.data?.length !== 0) {
                        REFRESH_CONVERSATION(response?.list?.data)

                        const numberOfMessage = response?.list?.data
                            ?.map(message => message?.sent_messages?.length)
                            ?.reduce(
                                (previousValue, currentValue) => previousValue + currentValue, 0,
                            )
                        REFRESH_MESSAGE_NUMBER(numberOfMessage)
                    }
                }
            }
        } catch (error) {
            fetchConversations()
            console.error('Fetch conversations error:', error.message)
        }
    }



    const handleFileChange = async (e) => {
        if (e.target.files) {

            const file = e.target.files[0]
            const fileUri = window.URL.createObjectURL(file)
            // console.log('Flie selected:', file)
            // console.log(file?.name?.split('.').pop())
            if (file.type.split("/")[0] === 'application') {

                const data = {
                    file: file,
                    uri: fileUri,
                    size: file?.size,
                    type: 'document',
                    extension: file?.name?.split('.').pop(),
                    mime_type: file?.type
                }
                setAttachments(attachments => [...attachments, data])
                setAttachmentsPreview(value => [...value, { uri: fileUri, type: 'document', extension: file?.name?.split('.').pop() }])

            }
            if (file.type.split("/")[0] === 'image') {

                setAttachmentsPreview(value => [...value, { uri: fileUri, type: 'image' }])

                new Compressor(file, {
                    quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                    success: async (compressedResult) => {
                        console.log('compressedResult:', compressedResult)
                        const { width, height } = await getImageParams(file)

                        const data = {
                            file: compressedResult,
                            uri: fileUri,
                            width,
                            height,
                            size: file?.size,
                            type: 'image',
                            extension: file?.type.split("/")[1],
                            mime_type: file?.type
                        }

                        setAttachments(attachments => [...attachments, data])

                    },
                });
            }

            if (file.type.split("/")[0] === 'video') {

                const megabytes = (file.size / 1048576).toFixed(0);
                if (megabytes > 50) {
                    setShowAlertVideo(true)
                } else {
                    // generate video thumbnail
                    const thumbnail = await generateVideoThumbnail(e.target.files[0]);
                    if (thumbnail) {

                        // set video thumbnail
                        const { thumbnailUri, thumbnailFile } = await convertBase64ToFile(thumbnail)

                        setAttachmentsPreview(value => [...value, { uri: thumbnailUri, type: 'video' }])

                        const meta = await getVideoMetadata(file)
                        // console.log('Video meta:', meta)

                        // load file
                        const data = {
                            file,
                            uri: thumbnailUri,
                            width: meta?.width,
                            height: meta?.height,
                            duration: meta?.duration,
                            size: file.size,
                            type: 'video',
                            thumbnail: thumbnailFile,
                            extension: file.type.split("/")[1],
                            mime_type: file.type
                        }

                        setAttachments(attachments => [...attachments, data])
                    }
                }
            }

        }
    };




    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.currentTime = 5;
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    };



    function getVideoMetadata(file) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                URL.revokeObjectURL(video.src);
                resolve({
                    duration: video.duration,
                    width: video.videoWidth,
                    height: video.videoHeight
                });
            };

            video.onerror = reject;
        });
    }


    const convertBase64ToFile = async (base64Image) => {
        const blob = await fetch(base64Image).then((res) => res.blob());
        const thumbnailFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        const thumbnailUri = URL.createObjectURL(thumbnailFile);
        // setImageUrl(imageUrl);
        // console.log('convertBase64ToFile:', file)
        return { thumbnailUri, thumbnailFile }
    };



    // reading a file to get height and width
    async function getImageParams(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader()

            reader.onload = async (e) => {
                var image = new Image()
                image.src = e.target.result
                await image.decode()

                resolve({ width: image.width, height: image.height })
            }
            reader.readAsDataURL(file)
        })
    }



    const handleDeleteAttachment = (uri) => {
        setAttachmentsPreview(attachmentsPreview.filter((attach) => attach?.uri !== uri))
        setAttachments(attachments.filter((attach) => attach?.uri !== uri))
    }



    // HANDLE SCROLL TOP TO PAGINATE
    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = refMessageBox?.current
        if (scrollTop <= clientHeight - scrollHeight + 1) {
            loadMoreMessages();
        }
    };


    const Header = () => {
        return (
            <div className={styles.header}>
                <button onClick={() => navigate(-1)} className={styles.backButtonHeader} >
                    <MdOutlineKeyboardBackspace size={32} />
                </button>
                <div>
                    {isLoading ?
                        <h4 style={{ marginLeft: '15px', marginBottom: '-2px' }}>
                            Message
                        </h4>
                        :
                        <ProfileCard user={userTchat} />
                    }
                </div>
                <button onClick={() => setShowMenu(true)} className={styles.menuButtonHeader}>
                    <TfiMenu size={28} color={color.dark} />
                </button>
            </div>
        )
    }


    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '1%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading')}
                    </h6>
                </div>
            </div>
        )
    }


    const renderAttachPreview = () => {
        if (attachmentsPreview.length !== 0) {
            return (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', paddingTop: '10px', paddingBottom: '5px', backgroundColor: '#f1f1f1' }}>
                    {attachmentsPreview?.map((attach, index) => {
                        if (attach?.type === 'document') {
                            return (
                                <div key={index.toString()} style={{ width: '50px', marginLeft: '15px' }}>
                                    <img
                                        src={
                                            image_extensions?.includes(attach?.extension) ?
                                                require('assets/docs/image_icon.png') :
                                                audio_extensions?.includes(attach?.extension) ?
                                                    require('assets/docs/audio.png') :
                                                    video_extensions?.includes(attach?.extension) ?
                                                        require('assets/docs/video_icon.png') :
                                                        word_extensions?.includes(attach?.extension) ?
                                                            require('assets/docs/word_icon.png') :
                                                            attach?.extension === 'pdf' ?
                                                                require('assets/docs/pdf_icon.png') :
                                                                excel_extensions?.includes(attach?.extension) ?
                                                                    require('assets/docs/excell_icon.png') :
                                                                    powerpoint_extensions?.includes(attach?.extension) ?
                                                                        require('assets/docs/pptx_icon.png') :
                                                                        access_extensions?.includes(attach?.extension) === 'accdb' ?
                                                                            require('assets/docs/access_icon.png') :
                                                                            attach?.extension === 'txt' ?
                                                                                require('assets/docs/txt.png') : require('assets/docs/file_icon.png')

                                        }
                                        width={50}
                                        height={50}
                                        alt=''
                                        style={{
                                            marginRight: '15px',
                                            // marginBottom: '10px',
                                            border: `1px ${color.primary} solid`,
                                            borderRadius: '3px'
                                        }}
                                    />
                                    <button
                                        style={{
                                            marginLeft: 'auto',
                                            marginTop: '-20px',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={() => handleDeleteAttachment(attach?.uri)}>
                                        <IoIosCloseCircle size={20} color={color.danger} />
                                    </button>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index.toString()} style={{ width: '50px', marginLeft: '15px' }}>
                                    <img
                                        src={attach?.uri}
                                        width={50}
                                        height={50}
                                        alt=''
                                        style={{
                                            marginRight: '15px',
                                            // marginBottom: '10px',
                                            border: `1px ${color.primary} solid`,
                                            borderRadius: '3px'
                                        }}
                                    />
                                    <button
                                        style={{
                                            marginLeft: 'auto',
                                            marginTop: '-20px',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={() => handleDeleteAttachment(attach?.uri)}>
                                        <IoIosCloseCircle size={20} color={color.danger} />
                                    </button>
                                </div>
                            )
                        }
                    })}
                </div>
            )
        }
    }


    return (
        <div className={styles.container}>
            {Header()}

            <div ref={refMessageBox} onScroll={handleScroll} id='scrollableDiv' className={styles.messageContainer}>
                {renderAttachPreview()}
                <InfiniteScroll
                    dataLength={messages?.length}
                    next={loadMoreMessages}
                    hasMore={hasMorePage}
                    loader={renderLoader}
                    scrollableTarget="scrollableDiv"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    inverse={true}>
                    {messages.map((message, index) => {
                        return (
                            <div key={index.toString()}>
                                <MessageCard
                                    user_id={user_id}
                                    received={userTchat}
                                    message={message}
                                    messages={messages}
                                    setMessages={setMessages}
                                />
                            </div>
                        )
                    })}
                    {hasMorePage &&
                        <div style={{ textAlign: 'center', padding: '30px', marginBottom: 'auto' }}>
                        <Spinner />
                    </div>
                    }
                </InfiniteScroll>
            </div>
            <div className={styles.inputMessage}>
                <div style={{ width: '100%' }}>

                    {isVoiceMessage ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <audio src={audioURL} controls style={{ height: '35px' }} />
                                <button onClick={startRecording} disabled={recording}>Enregistrer</button>
                                <button onClick={stopRecording} disabled={!recording}>Stop</button>
                                <button onClick={handleSendMessage} disabled={!audioURL}>Envoyer</button>
                            </div>
                            <button onClick={() => setIsVoiceMessage(false)}>
                                <AiFillCloseCircle size={30} color={color.primary} />
                            </button>
                        </div>
                        :
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <label
                                htmlFor="choice1"
                                onChange={(e) => handleFileChange(e)}
                                style={{
                                    borderRadius: '3px',
                                    padding: '7px',
                                    cursor: 'pointer',
                                }}>
                                <input accept="image/*, video/*, application/*" name="" type="file" id="choice1" hidden />
                                <RiAttachment2 size={30} color={color.primary} style={{ backgroudColor: '#01195f', border: '2px #01195f solid', borderRadius: '30px', padding: '3px' }} />
                            </label>

                            <InputEmoji
                                value={message}
                                onChange={setMessage}
                                // cleanOnEnter
                                keepOpened
                                onEnter={text => handleSendMessage(text)}
                                theme={'light'}
                                placeholder={t('write_your_message')}
                            />

                            {isLoadingRequest ?
                                <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                        variant={'primary'}
                                    />
                                </div>
                                :
                                <button
                                    onClick={handleSendMessage}
                                    type='submit'
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: color?.primary,
                                        borderRadius: '30px'
                                    }}>
                                    <RiSendPlaneFill size={25} color='white' style={{ marginTop: '2px' }} />
                                </button>
                            }
                        </div>}

                </div>

            </div>


            {/* Menu */}
            <Modal show={showMenu}>
                <Header title='Menu' type='modal' goBack={() => setShowMenu(false)} />

                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

                    <div
                        onClick={updateLastSeen}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        {authData?.user?.user_show_last_seen ? <AiOutlineEyeInvisible size={26} color='#808080' /> : <AiOutlineEye size={26} color='#808080' />}
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {authData?.user?.user_show_last_seen ? t('hide_your_last_seen') : t('show_your_last_seen')}
                        </span>
                    </div>

                    <div
                        onClick={deleteAllMessages}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        <MdDeleteOutline size={26} color='#808080' />
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {t('delete_all_messages')}
                        </span>
                    </div>
                    <div
                        onClick={blockBuddy}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        <RiUserUnfollowLine size={26} color='#808080' />
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {t('block')} {userTchat?.user_surname}  {userTchat?.user_name}
                        </span>
                    </div>

                </div>

            </Modal>


            <ModelAlert
                message={t('video_too_large_please_select_a_video_less_than_50mb')}
                show={showAlertVideo}
                setShow={setShowAlertVideo}
            />

        </div>
    )
}

export default OneToOneMessage