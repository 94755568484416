import { useTranslation } from 'react-i18next';

export default function useBadge() {

    const {t} = useTranslation()


    const type_badge_data =[
        { label: t('goal'), value: 'goal', icon: require('../../assets/badge/goal/cat_goal.png') },
        { label: t('business'), value: 'business', icon: require('../../assets/badge/business/cat_business.png') },
        { label: t('hobby'), value: 'hobby', icon: require('../../assets/badge/hobby/cat_hobby.png') },
        { label: t('food'), value: 'food', icon: require('../../assets/badge/food/cat_food.png') },
        { label: t('color'), value: 'color', icon: require('../../assets/badge/color/cat_color.png') }
    ]

    const goal_data = [
        { label: t('love'), value: 'love', icon: require('../../assets/badge/goal/love.png') },
        { label: t('god'), value: 'god', icon: require('../../assets/badge/goal/god.png') },
        { label: t('money'), value: 'money', icon: require('../../assets/badge/goal/money.png') },
        { label: t('power'), value: 'power', icon: require('../../assets/badge/goal/power.png') },
        { label: t('fame'), value: 'fame', icon: require('../../assets/badge/goal/celebrity.png') },
        { label: t('family'), value: 'family', icon: require('../../assets/badge/goal/family.png') },
        { label: t('humanitary'), value: 'humanitarian', icon: require('../../assets/badge/goal/humanitary.png') },
        { label: t('patriot'), value: 'patriot', icon: require('../../assets/badge/goal/patriot.png') },
        { label: t('peace'), value: 'peace', icon: require('../../assets/badge/goal/peace.png') },
        { label: 'LGBT', value: 'lgbt', icon: require('../../assets/badge/goal/lgbt.png') },
        { label: t('freedom'), value: 'freedom', icon: require('../../assets/badge/goal/freedom.png') },
        { label: t('saveThePlanet'), value: 'save-planet', icon: require('../../assets/badge/goal/save-planet.png') }
    ];

    const business_data = [
        { label: t('technology'), value: 'technology', icon: require('../../assets/badge/business/technology.png') },
        { label: t('finance'), value: 'finance', icon: require('../../assets/badge/business/finance.png') },
        { label: t('arts'), value: 'arts', icon: require('../../assets/badge/business/arts.png') },
        { label: t('health'), value: 'health', icon: require('../../assets/badge/business/health.png') },
        { label: t('foodIndustry'), value: 'food_industry', icon: require('../../assets/badge/business/food_industry.png') },
        { label: t('education'), value: 'education', icon: require('../../assets/badge/business/education.png') },
        { label: t('law'), value: 'law', icon: require('../../assets/badge/business/law.png') },
        { label: t('restaurant'), value: 'catering', icon: require('../../assets/badge/business/restaurant.png') },
        { label: t('communication'), value: 'communication', icon: require('../../assets/badge/business/communication.png') },
        { label: t('services'), value: 'services', icon: require('../../assets/badge/business/service.png') },
        { label: t('proAthlete'), value: 'pro-athlete', icon: require('../../assets/badge/business/pro-athlete.png') },
        { label: t('agriculture'), value: 'agriculture', icon: require('../../assets/badge/business/agriculture.png') },
    ];


    const hobby_data = [
        { label: t('travel'), value: 'travel', icon: require('../../assets/badge/hobby/travel.png') },
        { label: t('sport'), value: 'sport', icon: require('../../assets/badge/hobby/sport.png') },
        { label: t('music'), value: 'music', icon: require('../../assets/badge/hobby/music.png') },
        { label: t('videoGame'), value: 'video-game', icon: require('../../assets/badge/hobby/video-game.png') },
        { label: t('cinema'), value: 'cinema', icon: require('../../assets/badge/hobby/cinema.png') },
        { label: t('nightLife'), value: 'night_life', icon: require('../../assets/badge/hobby/night_life.png') },
        { label: t('communityLife'), value: 'community_life', icon: require('../../assets/badge/hobby/community_life.png') },
        { label: t('art'), value: 'art', icon: require('../../assets/badge/hobby/art.png') },
        { label: t('gardening'), value: 'gardening', icon: require('../../assets/badge/hobby/gardening.png') },
        { label: t('hike'), value: 'hiking', icon: require('../../assets/badge/hobby/hike.png') },
        { label: t('hunting'), value: 'hunting', icon: require('../../assets/badge/hobby/hunting.png') },
        { label: t('fishing'), value: 'fishing', icon: require('../../assets/badge/hobby/fishing.png') },
    ];


    const food_data = [
        { label: t('vegetarian'), value: 'vegetarian', icon: require('../../assets/badge/food/vegetarian.png') },
        { label: t('meat'), value: 'meat', icon: require('../../assets/badge/food/meat.png') },
        { label: t('fish'), value: 'fish', icon: require('../../assets/badge/food/fish.png') },
        { label: t('bread'), value: 'bread', icon: require('../../assets/badge/food/bread.png') },
        { label: t('cake'), value: 'cake', icon: require('../../assets/badge/food/cake.png') },
        { label: t('pasta'), value: 'pasta', icon: require('../../assets/badge/food/pasta.png') },
        { label: t('soup'), value: 'soup', icon: require('../../assets/badge/food/soup.png') },
        { label: t('seaFood'), value: 'sea_food', icon: require('../../assets/badge/food/sea_food.png') },
        { label: t('cereals'), value: 'cereal', icon: require('../../assets/badge/food/cereal.png') },
        { label: t('dairy'), value: 'dairy', icon: require('../../assets/badge/food/dairy.png') },
        { label: t('rice'), value: 'rice', icon: require('../../assets/badge/food/rice.png') },
        { label: t('wildMeat'), value: 'wild-meat', icon: require('../../assets/badge/food/wild-meat.png') },
    ];


    const color_data = [
        { label: t('red'), value: 'red', icon: '#e74c3c' },
        { label: t('blue'), value: 'blue', icon: '#0570E7' },
        { label: t('green'), value: 'green', icon: '#28A745' },
        { label: t('yellow'), value: 'yellow', icon: '#FFFF00' },
        { label: t('orange'), value: 'orange', icon: '#FF8C00' },
        { label: t('black'), value: 'black', icon: '#000000' },
        { label: t('white'), value: 'white', icon: '#FFFFFF' },
        { label: t('purple'), value: 'purple', icon: '#884DA7' },
        { label: t('pink'), value: 'pink', icon: '#ff00e4' },
        { label: t('brown'), value: 'brown', icon: '#8f5503' },
        { label: t('grey'), value: 'grey', icon: '#b4b4b4' },
        { label: t('beige'), value: 'beige', icon: '#f5dcb5' },
    ];


    return {type_badge_data, goal_data, business_data, hobby_data, food_data, color_data}
}


