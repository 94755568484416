
import Login from '../pages/login/Login'
import Register from '../pages/register/Register'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ConfirmPassword from '../pages/confirmPassword/ConfirmPassword'
import ForgetPassword from '../pages/forgetPassword/ForgetPassword'
import PrivacyPolicy from '../pages/legal/en/PrivacyPolicy'
import TermsConditions from '../pages/legal/en/TermsConditions'
import PolitiqueConfidentialite from '../pages/legal/fr/PolitiqueConfidentialite'
import ConditionsGenerales from '../pages/legal/fr/ConditionsGenerales'
import Support from '../pages/Support/Support'

function AuthStack() {
    return (
        <Routes>
            <Route path='*' element={<Login />} />
            <Route path='/' element={<Login />} />
            <Route path='login' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route path='confirm-password' element={<ConfirmPassword />} />
            <Route path='forget-password' element={<ForgetPassword />} />

            <Route path='legal/en/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='legal/en/terms-and-conditions' element={<TermsConditions />} />
            <Route path='legal/fr/politique-de-confidentialite' element={<PolitiqueConfidentialite />} />
            <Route path='legal/fr/conditions-generales/' element={<ConditionsGenerales />} />
            <Route path="support" element={<Support />} />
        </Routes>
    )
}

export default AuthStack