import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Badges from '../badges/Badges'
import ProfilePicture from '../ProfilePicture/ProfilePicture'

const ProfileCard = ({
    user,
    pictureSize = 40,
    sourceFrom = 'Home',
}) => {

    return (
        <Link to={sourceFrom === 'OneToOneConversation' ? null : `/profiles/user/${user?.user_username}`} style={{ textDecoration: 'none' }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '15px', paddingRight: '15px' }}>
                <ProfilePicture
                    user={user}
                    uri={user?.profile?.prof_picture}
                    size={pictureSize}
                />
                <div style={{ marginLeft: '10px', marginRight: '10px' }}>


                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h6 style={{ fontWeight: 'bold', color: '#414141', marginBottom: '-5px' }}>
                            {user?.user_surname} {user?.user_name}
                        </h6>
                        {(user?.user_verified || user?.user_verified_from_revenue_cat) &&
                            <img src={require('assets/images/verified.png')}
                                style={{
                                    width: 17,
                                    height: 17,
                                    marginLeft: 5,
                                    marginTop: 5
                                }}
                            />
                        }

                        {(user?.user_gold || user?.user_gold_from_revenue_cat) &&
                            <img src={require('assets/images/premium.png')}
                                style={{
                                    width: 17,
                                    height: 17,
                                    marginLeft: 5,
                                    marginTop: 5
                                }}
                            />
                        }
                    </div>

                    <span style={{ fontWeight: '500', color: '#808080', fontSize: '14px', }}>@{user?.user_username}</span>
                    <Badges user={user} />
                </div>
            </div >
        </Link>
    )
}

export default ProfileCard