import React from 'react'

const SondPicture = ({ uri }) => {
  return (
    <div style={{width:'100%'}}>
      <img src={uri}
        alt=''
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }} />
    </div>
  )
}

export default SondPicture