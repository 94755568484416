import React from 'react'
import './ButtonGmail.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import google from '../../../assets/images/google_icon.png';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

function ButtonGmail({
    onClick,
    isLoading = false,
    style
}) {

    /* STATE */
    const { t } = useTranslation()

    if (!isLoading) {
        return (
            <button onClick={onClick} style={style} className='ButtonGmail'>
                <img className='icon' src={google} alt='' />
                <span className='text'>{t('login_with_gmail')}</span>
            </button>
        )
    } else {
        return (
            <button style={style} className='ButtonGmail'>
                <Spinner
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    variant="gray"
                    className='spinner'
                />
            </button>
        )
    }
}

export default ButtonGmail