import React, { useState } from 'react';
import './ProfileContent.style.css'
import { MdOutlineDateRange, MdOutlineKeyboardBackspace, MdOutlinePublic } from 'react-icons/md';
import Opinion from '../Opinion/Opinion';
import { Modal, Spinner } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import Endbar from '../Endbar/Endbar';
import { useEffect } from 'react';
import { api } from '../../api/api';
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import { useTranslation } from 'react-i18next';
import { AiOutlineGlobal, AiOutlineDisconnect } from 'react-icons/ai'
import { FiTag } from 'react-icons/fi'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { FiMail, FiFileText } from 'react-icons/fi'
import { MdPersonAddAlt1 } from 'react-icons/md'
import { TbPlugConnected } from 'react-icons/tb'
import { FaUsers, FaUserTimes } from 'react-icons/fa'
import { color } from '../../hooks/Utils/color';
import Moment from 'react-moment';
import { formatCash } from '../../hooks/Utils/parsing';
import Audience from '../Audience/Audience';
import InfiniteScroll from 'react-infinite-scroll-component';
import Badges from '../badges/Badges';

const Profile = ({
    user_id,
    item,
    show,
    setShow,
    updateOpinion = () => null,
    sourceFrom,
}) => {

    const { data: authData } = useAuthReducer()
    Moment.globalLocale = localStorage.getItem('language');
    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const [userProfile, setUserProfile] = useState(null)
    const [opinions, setOpinions] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [totalUserLikes, setTotalUserLikes] = useState(0)
    const [showAudience, setShowAudience] = useState(false)


    useEffect(() => {
        if (!show) {
            return
        }
        setIsLoading(true)
        fetchProfile()
    }, [show])

    const fetchProfile = async () => {
        try {
            const request = await api(`api/profile/v2/user-profile-info/${user_id}?page=${page}`, 'GET', null, accessToken);
            const response = await request.json()
            console.log('Response user profile: ', response)
            if (response.success) {
                setUserProfile(response?.user_profile_info[0])
                setOpinions(response?.opinions?.data)
                setTotalUserLikes(response?.soutiens_count)
                setPage(item => item + 1)
            }
            setIsLoading(false)
        } catch (error) {
            console.error('Get opinion comments:', error.message);
        }
    }


    const approveBuddy = async () => {
        const body = { user_id }
        try {
            const request = await api(
                'api/profile/approve-request-buddy',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile()

                    //   sendNotification(user_id, {
                    //     title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //     body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a accepté votre demande.`,
                    //     url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //   })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const requestBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/request-buddy',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            console.log(response, 'response off request buddy')
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile()

                    //   sendNotification(user_id, {
                    //     title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //     body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} vous a envoyé une demande de pote.`,
                    //     url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //   })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const cancelBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/request-cancel-buddy',
                'DELETE',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile()

                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }


    const deleteBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/delete-buddy',
                'DELETE',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile()

                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }



    const becomeOrDeleteFan = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/fan-treatment',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            console.log(response, 'RESPONSE OF BECOME OR DELETE FAN')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchProfile()

                    //   if (response?.notification) {
                    //     sendNotification(user_id, {
                    //       title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //       body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} est devenu votre fan.`,
                    //       url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //     })
                    //   }

                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const blockBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/blocked',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile()
                    // navigation.navigate('Audience', {
                    //     user_id: authStateContext?.user?.user_id,
                    //     user_surname: authStateContext?.user?.user_surname,
                    //     user_name: authStateContext?.user?.user_name,
                    //     user_username: authStateContext?.user?.user_username,
                    //     prof_picture: authStateContext?.user?.profile?.prof_picture,
                    //     sourceFrom: 'Profil/block',
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }





    const renderLoading = () => {
        return (
            <div style={{ borderLeft: '1px #f1f1f1 solid', borderRight: '1px #f1f1f1 solid' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                    <div style={{ textAlign: 'center' }}>
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant='secondary'
                            style={{
                                width: '30px',
                                height: '30px'
                            }}
                        />
                        <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
                            {t('loading_profile')}
                        </h6>
                    </div>
                </div>
            </div>
        )
    }



    const renderBuddyButton = () => {
        if (userProfile?.invite_sent?.length == 0 &&
            userProfile?.invite_received?.length == 0 &&
            userProfile?.friends_accepted?.length == 0 &&
            userProfile?.friends_invited?.length == 0) {
            return (
                <button
                    onClick={requestBuddy}
                    className='button'
                    style={{
                        backgroundColor: '#1880a1',
                        color: "white",
                    }}>
                    <MdPersonAddAlt1 size={18} style={{ marginRight: '5px' }} />
                    {t('become_buddy')}
                </button>
            )
        }

        if (userProfile?.friends_invited?.length === 0 &&
            userProfile?.friends_accepted?.length === 0 &&
            userProfile?.invite_sent?.length === 0 &&
            userProfile?.invite_received?.length !== 0) {
            return (
                <button
                    onClick={cancelBuddy}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        color: "#1880a1",
                        fontWeight: '600',
                        border: '1.8px #1880a1 solid'
                    }}>
                    <FaUserTimes size={18} style={{ marginRight: '5px' }} />
                    {t('cancel')}
                </button>
            )
        }

        if (userProfile?.friends_accepted?.length !== 0 ||
            userProfile?.friends_invited?.length !== 0) {
            return (
                <button
                    onClick={deleteBuddy}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        color: "#1880a1",
                        fontWeight: '600',
                        border: '1.8px #1880a1 solid'
                    }}>
                    <FaUserTimes size={18} style={{ marginRight: '5px' }} />
                    {t('you_are_buddy')}
                </button>
            )
        }

        if (userProfile?.invite_sent?.length !== 0) {
            return (
                <button
                    onClick={approveBuddy}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        color: "#1880a1",
                        fontWeight: '600',
                        border: '1.8px #1880a1 solid'
                    }}>
                    <MdPersonAddAlt1 size={18} style={{ marginRight: '5px' }} />
                    {t('approve')}
                </button>
            )
        }

    }



    const renderFanButton = () => {
        if (userProfile?.followers?.length !== 0) {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        color: "#1880a1",
                        fontWeight: '600',
                        border: '1.8px #1880a1 solid'
                    }}>
                    <AiOutlineDisconnect size={18} style={{ marginRight: '5px' }} />
                    {t('you_are_fan')}
                </button>
            )
        } else {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button'
                    style={{
                        backgroundColor: '#1880a1',
                        color: "white",
                    }}>
                    <TbPlugConnected size={18} style={{ marginRight: '5px' }} />
                    {t('become_fan')}
                </button>
            )
        }
    }



    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }


    const renderProfile = () => {
        return (
            <div className='profile'>
                <Sidebar />
                <div className='content'>

                    <div className='topbar'>
                        <div onClick={() => setShow(false)} className='button-back' >
                            <MdOutlineKeyboardBackspace size={32} />
                        </div>
                        {userProfile ?
                            <h5 className='title'>
                                {userProfile?.user_surname} {userProfile?.user_name}
                            </h5>
                            :
                            <h5 className='title'>
                                {t('profile')}
                            </h5>
                        }
                    </div>

                    {isLoading ? renderLoading() : (

                        <div className='content-body'>

                            <img src={userProfile?.profile?.prof_cover} className="cover" alt='cover' />

                            <div className='picture'>
                                <ProfilePicture
                                    user={userProfile}
                                    uri={userProfile?.profile?.prof_picture}
                                    size={140}
                                />
                                <div className='account'>
                                    <h3>{userProfile?.user_surname} {userProfile?.user_name}</h3>
                                    <h6 style={{ marginTop: '-5px', color: '#808080' }}>
                                        @{userProfile?.user_username}
                                    </h6>
                                    <Badges size={20} user={userProfile} />
                                </div>

                            </div>


                            {/* RENDER DESCRIPTION PROFILE */}
                            <div className='description'>

                                {userProfile?.profile?.prof_title &&
                                    <div className='box-info'>
                                        <FiTag size={22} color="#808080" />
                                        <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                            {userProfile?.profile?.prof_title}
                                        </span>
                                    </div>
                                }

                                {userProfile?.profile?.prof_description &&
                                    <div className='box-info'>
                                        {/* <FiFileText size={46} style={{width: '75px', height: '75px'}} color="#808080" /> */}
                                        <span style={{ fontSize: '14px', color: "#414141" }}>
                                            {userProfile?.profile?.prof_description}
                                        </span>
                                    </div>
                                }

                                {/* VILLE */}
                                {userProfile?.user_ville &&
                                    <div className='box-info'>
                                        <HiOutlineLocationMarker size={24} color="#808080" />
                                        <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                            {userProfile?.user_ville}
                                        </span>
                                    </div>
                                }

                                {userProfile?.prof_email &&
                                    <div className='box-info'>
                                        <FiMail size={22} color="#808080" />
                                        <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                            {userProfile?.prof_email}
                                        </span>
                                    </div>
                                }

                                {userProfile?.profile?.prof_site_web &&
                                    <div className='box-info'>
                                        <AiOutlineGlobal size={22} color="#808080" />
                                        <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                            {userProfile?.profile?.prof_site_web}
                                        </span>
                                    </div>
                                }

                                <div className='box-info'>
                                    <MdOutlineDateRange size={22} color="#808080" />
                                    <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                        Inscrit(e) depuis {' '}
                                        <Moment format="MMMM YYYY" local={true} locale={'fr'}>
                                            {userProfile?.user_created_at?.sond_publishing}
                                        </Moment>
                                    </span>
                                </div>

                            </div>

                            {authData?.user?.user_id != user_id &&
                                <div className='button-box'>
                                    {renderBuddyButton()}
                                    {renderFanButton()}
                                </div>
                            }

                            <div onClick={() => setShowAudience(true)} style={{ cursor: 'pointer' }} className='audience'>

                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                    <FaUsers color={color.secondary} size={28} style={{ marginRight: '5px' }} />
                                    <h5 style={{ fontWeight: 'bold', color: color.secondary }}>Audience</h5>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(userProfile?.friends_accepted_count + userProfile?.friends_invited_count)} Potes
                                    </h5>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(userProfile?.followers_count)} Fans
                                    </h5>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', paddingLeft: '10%', paddingRight: '10%' }}>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(userProfile?.followings_count)} Fans de
                                    </h5>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(totalUserLikes)} J'aimes
                                    </h5>
                                </div>

                            </div>

                            <div className='audience'>

                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                    <MdOutlinePublic color={color.secondary} size={28} style={{ marginRight: '5px' }} />
                                    <h5 style={{ fontWeight: 'bold', color: color.secondary }}>Interactions</h5>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(userProfile?.opinions_count)} Opinion(s)
                                    </h5>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(userProfile?.votes_count)} Vote(s)
                                    </h5>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', paddingLeft: '10%', paddingRight: '10%' }}>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(userProfile?.top_comments_count)} Top commen...
                                    </h5>
                                    <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                        {formatCash(userProfile?.top_suggestions_count)} Top sugges...
                                    </h5>
                                </div>

                            </div>


                            {/* RENDER LIST OPINION */}
                            {/* {opinions.map(opinion => {
                                return (
                                    <Opinion
                                        item={opinion}
                                    />
                                )
                            })} */}



                        </div>
                    )}

                </div>
                <Endbar />
            </div>
        )
    }


    return (
        <Modal show={show} fullscreen={true} animation={false}>
            {/* <Modal.Body> */}
            <InfiniteScroll
                dataLength={opinions.length}
                next={() => alert('Super')}
                hasMore={hasMore}
                loader={renderLoader()}
                scrollThreshold={0.9}
                useWindow={false}
                style={{
                    width: '100%',
                    height: '100vh',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}>
                {opinions.map((item, index) => {
                    return (
                        <div>
                            <Opinion
                                key={index}
                                item={item}
                                index={index}
                                // updateOpinion={UPDATE_HOME_OPINION}
                                sourceFrom={'Profile'}
                            />
                        </div>
                    )
                })}
            </InfiniteScroll>

            <Audience
                user={userProfile}
                show={showAudience}
                setShow={setShowAudience}
            />
            {/* </Modal.Body> */}
        </Modal>
    );
}

export default Profile