import {usePeopleMatchReducer } from "../ReducerHooks/ReducerHooks"


const useReducerPeopleMatchAction = () => {

    const { store: peopleMatchStore } = usePeopleMatchReducer()

    const REFRESH_PEOPLE = (data) => {
        peopleMatchStore({
            type: 'REFRESH_PEOPLE',
            payload: data
        })
    }

    const CLEAR_PEOPLE = (data) => {
        peopleMatchStore({
            type: 'CLEAR_PEOPLE'
        })
    }

    return { REFRESH_PEOPLE, CLEAR_PEOPLE}
}

export default useReducerPeopleMatchAction